import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ucsfInstance } from '../../../core/axios/ucsf';
import CustomButton from '../../../core/components/custom-button/custom-button.component';
import FormInput from '../../../core/components/form-input/form-input.component';
import { RegisterAuthCommonValidation } from '../../../core/components/pre-auth-layout/pre-auth-common-validation.component';
import PreAuthLayoutComponent from '../../../core/components/pre-auth-layout/pre-auth-layout.component';
import AppService from '../../../core/services/app-service';
import { preAuthBtnStyle } from '../../../core/style-config/elements.component';
import { COMPONENT_APIS } from '../../../core/utils/omi-api';
import formValidityCheck from '../../../core/utils/validator';
import { setLoader } from '../../../redux/app/app.actions';
import registerForm from './forms/register.forms';
import './register.styles.scss';

const RegisterPage = ({ history, match }) => {
    const formElementsArray = [];
    const d = useDispatch();
    const [userData, setUserData] = useState({
        registerFormData: { ...registerForm },
        formIsValid: false,
        loading: false
    });
    const { registerFormData } = userData;

    const handleSubmit = async event => {
        AppService.clearAllCookies();
        event.preventDefault();
        d(setLoader(true));
        const newUserObject = {
            first_name: registerFormData.firstName.value,
            last_name: registerFormData.lastName.value,
            email: registerFormData.email.value,
            username: registerFormData.userName.value,
            password: AppService.encryptData(registerFormData.password.value),
            confirm_password: registerFormData.confirmPassword.value,
            institution: registerFormData.institution.value,
            orcid_id: registerFormData.orcidID.value
        };
        for (let key in newUserObject) {
            if (!newUserObject[key]) {
                delete newUserObject[key];
            }
        }
        ucsfInstance.post(COMPONENT_APIS.USER_REG, newUserObject).then(({ data }) => {
            AppService.setNotification({
                title: 'User Registration Successful',
                message: `User Registration for ${newUserObject.first_name} ${newUserObject.last_name} is completed.`,
                type: 'success',
                duration: 1500
            });
            setTimeout(() => {
                d(setLoader(false));
                AppService.setNotification({
                    title: 'Verify Email!',
                    message: `A verification link has been sent to the email that you've provided. Please click on the link inside to verify.`,
                    type: 'info',
                    duration: 3000
                });
                history.push(`/login`);
            }, 1500);
        }).catch(err => {
            d(setLoader(false));
            const regErr = err?.response?.data?.error || err?.error;
            AppService.setNotification({
                title: 'Error',
                message: regErr || `Something went wrong. Please try again.`,
                type: 'danger'
            });
        }); // history.push(`${match.url}/get-code`);
        clearFormFields();
    }

    const inputChangedHandler = (event, id) => {
        let formIsValid = true;
        const updatedRegisterForm = { ...registerFormData };
        const updatedFormElement = { ...updatedRegisterForm[id] };
        updatedFormElement.value = event.target.value;
        const { validity, errorMessage } = formValidityCheck(updatedFormElement, updatedRegisterForm);
        updatedFormElement.valid = validity;
        updatedFormElement.validation.message = errorMessage;
        updatedFormElement.touched = true;
        updatedRegisterForm[id] = updatedFormElement;
        for (let identifier in updatedRegisterForm) {
            formIsValid = updatedRegisterForm[identifier].valid && formIsValid;
        }
        setUserData({ registerFormData: updatedRegisterForm, formIsValid });
    }

    const clearFormFields = () => {
        setUserData({
            registerFormData: { ...registerForm },
            formIsValid: false,
            loading: false
        });
    }

    for (let key in registerFormData) {
        formElementsArray.push({
            id: key,
            config: registerFormData[key]
        });
    }

    let form = (
        <form className="sign-up-form" onSubmit={handleSubmit}>
            <div className="sign-up-form-fields">
                {
                    formElementsArray.map(formElement => (
                        <FormInput
                            key={formElement.id}
                            label={formElement.config.label}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            value={formElement.config.value}
                            invalid={!formElement.config.valid}
                            shouldValidate={formElement.config.validation}
                            touched={formElement.config.touched}
                            errorMessage={formElement.config.validation.message}
                            changed={(event) => inputChangedHandler(event, formElement.id)}
                            blurred={(event) => inputChangedHandler(event, formElement.id)} />
                    ))
                }
            </div>
            <CustomButton
                type="submit"
                value="Submit"
                inverted="inverted"
                style={preAuthBtnStyle}
                disabled={!userData.formIsValid}>
                {/* NEXT */}
                REGISTER
            </CustomButton>
        </form>
    );

    return (
        <PreAuthLayoutComponent routeType='register'>
            <div className='new-user-reg'>User Registration</div>
            <RegisterAuthCommonValidation>
                Please enter the following user information and register. (<span style={{ color: '#ec0000' }}>*</span>&nbsp;Required fields)
            </RegisterAuthCommonValidation>
            {form}
            <CustomButton
                type="button"
                inverted="inverted"
                style={preAuthBtnStyle}
                click={(e) => history.push('/')}>
                BACK
            </CustomButton>
            <div className='link-container'>
                <div className='link-for-login'>
                    Registered already? <span onClick={() => history.push('/login')}>Login</span>
                </div>
            </div>
        </PreAuthLayoutComponent>
    );
};

export default RegisterPage;