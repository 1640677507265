export const networkTreeData_BACKUP = {
    name: 'Raw Data',
    size: 'xsmall',
    hasArrow: false,
    nodeSvgShape: {
        shape: 'none'
    },
    children: [{
        name: 'RDP',
        type: 'rdp',
        size: 'circle',
        hasArrow: false,
        hideNodeLabel: false,
        nodeSvgShape: {
            shape: 'none', // 'circle',
            shapeProps: {
                r: 20,
                fill: '#F25E3B',
            }
        },
        x: 180,
        children: [{
            name: 'Preprocessed Data',
            size: 'large',
            hasArrow: true,
            nodeSvgShape: {
                shape: 'none'
            },
            x: 250,
            children: [{
                name: 'MDC',
                type: 'mdc',
                size: 'circle',
                hasArrow: false,
                hideNodeLabel: false,
                nodeSvgShape: {
                    shape: 'none', // 'circle',
                    shapeProps: {
                        r: 20,
                        fill: '#2390BB',
                    }
                },
                children: [{
                    name: 'Data Collection',
                    size: 'medium',
                    hasArrow: true,
                    nodeSvgShape: {
                        shape: 'none'
                    },
                    children: [
                        {
                            name: 'Dataset 1',
                            size: 'small',
                            hasArrow: true,
                            nodeSvgShape: {
                                shape: 'none'
                            },
                            children: [{
                                name: 'SN',
                                type: 'sn',
                                size: 'circle',
                                hasArrow: false,
                                hideNodeLabel: false,
                                nodeSvgShape: {
                                    shape: 'none'
                                },
                                children: [{
                                    name: 'Processed Data',
                                    size: 'medium',
                                    hasArrow: true,
                                    nodeSvgShape: {
                                        shape: 'none'
                                    },
                                    position: {
                                        x: -95
                                    },
                                    children: [{
                                        name: 'FM',
                                        type: 'fm',
                                        size: 'circle',
                                        hasArrow: false,
                                        hideNodeLabel: false,
                                        nodeSvgShape: {
                                            shape: 'none'
                                        },
                                        children: [{
                                            name: 'Covariation Networks',
                                            size: 'large',
                                            hasArrow: true,
                                            nodeSvgShape: {
                                                shape: 'none'
                                            },
                                            position: {
                                                x: -95
                                            },
                                        }]
                                    }]
                                }]
                            }]
                        },
                        {
                            name: 'Dataset 2',
                            size: 'small',
                            hasArrow: true,
                            nodeSvgShape: {
                                shape: 'none'
                            },
                            children: [{
                                name: 'SN',
                                type: 'sn',
                                size: 'circle',
                                hasArrow: false,
                                hideNodeLabel: false,
                                nodeSvgShape: {
                                    shape: 'none'
                                },
                                children: [{
                                    name: 'Processed Data',
                                    size: 'medium',
                                    hasArrow: true,
                                    nodeSvgShape: {
                                        shape: 'none'
                                    },
                                    position: {
                                        x: -95
                                    },
                                    children: [{
                                        name: 'FM',
                                        type: 'fm',
                                        size: 'circle',
                                        hasArrow: false,
                                        hideNodeLabel: false,
                                        nodeSvgShape: {
                                            shape: 'none'
                                        },
                                        children: [{
                                            name: 'Covariation Networks',
                                            size: 'large',
                                            hasArrow: true,
                                            nodeSvgShape: {
                                                shape: 'none'
                                            },
                                            position: {
                                                x: -95
                                            },
                                        }]
                                    }]
                                }]
                            }]
                        },
                        {
                            name: 'Dataset 3',
                            size: 'small',
                            hasArrow: true,
                            nodeSvgShape: {
                                shape: 'none'
                            },
                            children: [{
                                name: 'SN',
                                type: 'sn',
                                size: 'circle',
                                hasArrow: false,
                                hideNodeLabel: false,
                                nodeSvgShape: {
                                    shape: 'none'
                                },
                                children: [{
                                    name: 'Processed Data',
                                    size: 'medium',
                                    hasArrow: true,
                                    nodeSvgShape: {
                                        shape: 'none'
                                    },
                                    position: {
                                        x: -95
                                    },
                                    children: [{
                                        name: 'FM',
                                        type: 'fm',
                                        size: 'circle',
                                        hasArrow: false,
                                        hideNodeLabel: false,
                                        nodeSvgShape: {
                                            shape: 'none'
                                        },
                                        children: [{
                                            name: 'Covariation Networks',
                                            size: 'large',
                                            hasArrow: true,
                                            nodeSvgShape: {
                                                shape: 'none'
                                            },
                                            position: {
                                                x: -95
                                            },
                                        }]
                                    }]
                                }]
                            }]
                        }
                    ],
                }]
            }]
        }]
    }]
};

export const treeDataBackup = [
    {
        name: 'Data Collection',
        size: 'medium',
        /* attributes: {
            keyA: 'val A',
            keyB: 'val B',
            keyC: 'val C'
        }, */
        nodeSvgShape: {
            shape: 'none', // 'circle',
            /* shapeProps: {
                r: 20,
                fill: 'blue',
            }, */
        },
        children: [
            {
                name: 'Dataset 1',
                size: 'small',
                nodeSvgShape: {
                    shape: 'none', // 'rect',
                    /* shapeProps: {
                        width: 150,
                        height: 50,
                        x: -25,
                        y: -25,
                        rx: 12,
                        ry: 12,
                        fill: 'red'
                    }, */
                }
            },
            {
                name: 'Dataset 2',
                size: 'small',
                nodeSvgShape: {
                    shape: 'none', // 'circle',
                    /* shapeProps: {
                        r: 20,
                        fill: 'white',
                    }, */
                },
            },
            {
                name: 'Dataset 3',
                size: 'small',
                nodeSvgShape: {
                    shape: 'none'
                },
            }
        ],
    },
];

export const networkTreeData_BACKUP2 = {
    "_id": "b532af6ae35a485e9f1867306f38f94e",
    "name": "Source",
    "attributes": [],
    "files": [],
    "children": [
        {
            "name": "MDC",
            "type": "transformation",
            "attributes": {
                "Analyst": "",
                "Version": "",
                "Date Analyzed": "",
                "Working Directory": ""
            },
            "files": [
                {
                    "key": "Oldham_DC_16-59-32/Oldham_makeDC_sessionInfo_16-59-32.txt",
                    "name": "Oldham_makeDC_sessionInfo_16-59-32.txt"
                },
                {
                    "key": "Oldham_DC_16-59-32/Oldham_QA_log_16-59-32.csv",
                    "name": "Oldham_QA_log_16-59-32.csv"
                },
                {
                    "key": "Oldham_DC_16-59-32/E-MTAB-2768_makeDC_driver_16-59-32.R",
                    "name": "E-MTAB-2768_makeDC_driver_16-59-32.R"
                }
            ],
            "children": [
                {
                    "name": "Data Collection Attributes",
                    "attributes": [
                        {
                            "Title": "Labreche|E-MTAB-2768|2015",
                            "Owner": "Michael Oldham",
                            "Availability": "Lab",
                            "Number of Individuals": "71",
                            "Number of Samples": "71"
                        }
                    ],
                    "files": [
                        {
                            "key": "Oldham_DC_16-59-32/Oldham_DC_attributes_16-59-32.csv",
                            "name": "Oldham_DC_attributes_16-59-32.csv"
                        },
                        {
                            "key": "Oldham_DC_16-59-32/Oldham_DC_sample_attributes_16-59-32.csv",
                            "name": "Oldham_DC_sample_attributes_16-59-32.csv"
                        }
                    ],
                    "children": []
                }
            ]
        },
        {
            "_index": "network",
            "_id": "c84612b646cc44348de93856307b32af",
            "name": "Raw Data",
            "attributes": {
                "Raw Data Location": "NA"
            },
            "files": [
                "NA"
            ],
            "children": [{
                "name": "RDP",
                "type": "transformation",
                "attributes": {
                    "Analyst": "mRNA",
                    "Raw Data Processing Code": "E-MTAB-2768_log.R"
                },
                "files": [
                    {
                        "key": "Oldham_DC_16-59-32/Oldham_DS1_16-59-32/RDP/E-MTAB-2768_log_16-59-32.R",
                        "name": "E-MTAB-2768_log_16-59-32.R"
                    }
                ],
                "children": [{
                    "name": "Dataset",
                    "attributes": {
                        "Title": "Labreche|E-MTAB-2768|2015",
                        "Owner": "Michael Oldham",
                        "Analyst": "Original authors",
                        "Availability": "Lab",
                        "Analyte": "mRNA",
                        "Technology": "Microarray",
                        "Platform": "Affymetrix HGU133 Plus 2.0",
                        "Quantification Scale": "Continuous (log2)",
                        "Number of Features": "42548",
                        "Unique Identifier": "PROBEID"
                    },
                    "files": [
                        {
                            "key": "Oldham_DC_16-59-32/Oldham_DS1_16-59-32/Oldham_DS1_analyte_metadata_16-59-32.csv",
                            "name": "Oldham_DS1_analyte_metadata_16-59-32.csv"
                        },
                        {
                            "key": "Oldham_DC_16-59-32/Oldham_DS1_16-59-32/Oldham_DS1_analyte_data_16-59-32.csv",
                            "name": "Oldham_DS1_analyte_data_16-59-32.csv"
                        },
                        {
                            "key": "Oldham_DC_16-59-32/Oldham_DS1_16-59-32/Oldham_DS1_attributes_16-59-32.csv",
                            "name": "Oldham_DS1_attributes_16-59-32.csv"
                        }
                    ],
                    "children": []
                }]
            }]
        }
    ]
}

export const networkTreeData = {
    "name": "Source",
    "type": null,
    "attributes": [
        {
            "Source": "CGGA",
            "Accession ID": [
                "NA"
            ],
            "BioProject ID": [
                "NA"
            ],
            "PubMed ID": [
                "25135958",
                "28291232",
                "28838912",
                "27564467"
            ]
        }
    ],
    "files": [
        {
            "key": "CGGA_makeDC_10-39-23/CGGA_DS1_10-39-23/CGGA_DS1_attributes_10-39-23.csv",
            "name": "CGGA_DS1_attributes_10-39-23.csv",
            "size": 1650
        },
        {
            "key": "CGGA_makeDC_10-39-23/CGGA_DS2_10-39-23/CGGA_DS2_attributes_10-39-23.csv",
            "name": "CGGA_DS2_attributes_10-39-23.csv",
            "size": 2156
        },
        {
            "key": "CGGA_makeDC_10-39-23/CGGA_DS3_10-39-23/CGGA_DS3_attributes_10-39-23.csv",
            "name": "CGGA_DS3_attributes_10-39-23.csv",
            "size": 2362
        }
    ],
    "children": [
        {
            "name": "MDC",
            "type": "transformation",
            "attributes": {
                "Analyst": "Rebecca Eliscu",
                "Version": null,
                "Date Analyzed": "2022-03-29",
                "Working Directory": "/mnt/bdata/rebecca/omicon/glioma_meta_datasets_processed/CGGA/makeDC"
            },
            "files": [
                {
                    "key": "CGGA_makeDC_10-39-23/CGGA_QA_log_10-39-23.csv",
                    "name": "CGGA_QA_log_10-39-23.csv",
                    "size": 16915
                },
                {
                    "key": "CGGA_makeDC_10-39-23/CGGA_makeDC_sessionInfo_10-39-23.txt",
                    "name": "CGGA_makeDC_sessionInfo_10-39-23.txt",
                    "size": 12650
                },
                {
                    "key": "CGGA_makeDC_10-39-23/makeDC.driver_10-39-23.R",
                    "name": "makeDC.driver_10-39-23.R",
                    "size": 12167
                }
            ],
            "children": [
                {
                    "name": "Data Collection Attributes",
                    "type": null,
                    "attributes": {
                        "Title": "CGGA",
                        "Owner": [
                            "Rebecca Eliscu"
                        ],
                        "Availability": "Lab",
                        "Number of Individuals": "1220",
                        "Number of Samples": "1220",
                        "_id": "fa89ac886a6b4b56a0b2fb9430844c1b"
                    },
                    "files": [
                        {
                            "key": "CGGA_makeDC_10-39-23/CGGA_DC_attributes_10-39-23.csv",
                            "name": "CGGA_DC_attributes_10-39-23.csv",
                            "size": 243
                        },
                        {
                            "key": "CGGA_makeDC_10-39-23/CGGA_DC_sample_attributes_10-39-23.csv",
                            "name": "CGGA_DC_sample_attributes_10-39-23.csv",
                            "size": 297246
                        }
                    ],
                    "children": []
                }
            ]
        },
        {
            "name": "Raw Data",
            "type": null,
            "attributes": {
                "Raw Data Location": "http://cgga.org.cn/download.jsp"
            },
            "files": [
                {
                    "name": "http://cgga.org.cn/download.jsp",
                    "key": null
                }
            ],
            "children": [
                {
                    "name": "RDP",
                    "type": "transformation",
                    "attributes": {
                        "Analyst": [
                            "Original authors"
                        ]
                    },
                    "files": [
                        {
                            "key": "CGGA_makeDC_10-39-23/CGGA_DS2_10-39-23/RDP/RDP_RNAseq.txt",
                            "name": "RDP_RNAseq.txt"
                        }
                    ],
                    "children": [
                        {
                            "name": "B.1",
                            "type": "Dataset",
                            "attributes": {
                                "Title": "CGGA RNA-seq 325 EXT",
                                "Owner": [
                                    "Rebecca Eliscu"
                                ],
                                "Analyst": [
                                    "Original authors"
                                ],
                                "Availability": "Lab",
                                "Analyte": [
                                    "mRNA"
                                ],
                                "Technology": [
                                    "Sequencer"
                                ],
                                "Platform": [
                                    "Illumina HiSeq 2000"
                                ],
                                "Quantification Scale": "FPKM",
                                "Number of Features": "24326",
                                "Unique Identifier": "SYMBOL",
                                "Transformations": "EXT",
                                "Number of Samples": "325",
                                "Number of Individuals": "325",
                                "_id": "6d7a654aac0f4948a118a780171478bf"
                            },
                            "files": [
                                {
                                    "key": "CGGA_makeDC_10-39-23/CGGA_DS2_10-39-23/CGGA_DS2_feature_metadata_10-39-23.csv",
                                    "name": "CGGA_DS2_feature_metadata_10-39-23.csv",
                                    "size": 379916
                                },
                                {
                                    "key": "CGGA_makeDC_10-39-23/CGGA_DS2_10-39-23/CGGA_DS2_sample_attributes_10-39-23.csv",
                                    "name": "CGGA_DS2_sample_attributes_10-39-23.csv",
                                    "size": 79660
                                },
                                {
                                    "key": "CGGA_makeDC_10-39-23/CGGA_DS2_10-39-23/CGGA_DS2_analyte_data_10-39-23.csv",
                                    "name": "CGGA_DS2_analyte_data_10-39-23.csv",
                                    "size": 56082022
                                },
                                {
                                    "key": "CGGA_makeDC_10-39-23/CGGA_DS2_10-39-23/CGGA_DS2_attributes_10-39-23.csv",
                                    "name": "CGGA_DS2_attributes_10-39-23.csv",
                                    "size": 2156
                                }
                            ],
                            "children": [
                                {
                                    "name": "OR",
                                    "type": "transformation",
                                    "attributes": {
                                        "Analyst": [
                                            "Rebecca Eliscu"
                                        ],
                                        "Owner": [
                                            "Rebecca Eliscu"
                                        ]
                                    },
                                    "files": [
                                        {
                                            "name": "All_change_by_round.pdf",
                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/All_change_by_round.pdf",
                                            "size": 5101
                                        },
                                        {
                                            "name": "All_rd_1_pc1.pdf",
                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/All_rd_1_pc1.pdf",
                                            "size": 61619
                                        },
                                        {
                                            "name": "All_rd_2_pc1.pdf",
                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/All_rd_2_pc1.pdf",
                                            "size": 61194
                                        },
                                        {
                                            "name": "All_rd_3_pc1.pdf",
                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/All_rd_3_pc1.pdf",
                                            "size": 61411
                                        },
                                        {
                                            "name": "All_rd_4_pc1.pdf",
                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/All_rd_4_pc1.pdf",
                                            "size": 61428
                                        },
                                        {
                                            "name": "All_rd_5_pc1.pdf",
                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/All_rd_5_pc1.pdf",
                                            "size": 61006
                                        },
                                        {
                                            "name": "All_rd_Qnorm_pc1.pdf",
                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/All_rd_Qnorm_pc1.pdf",
                                            "size": 60804
                                        },
                                        {
                                            "name": "CGGA_RNAseq_325_All_315_final_sample_metrics.csv",
                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/CGGA_RNAseq_325_All_315_final_sample_metrics.csv",
                                            "size": 32551
                                        },
                                        {
                                            "name": "SN_driver_03-49-08.R",
                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/SN_driver_03-49-08.R",
                                            "size": 2865
                                        },
                                        {
                                            "name": "SampleNetwork_1.08.r",
                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/SampleNetwork_1.08.r",
                                            "size": 79461
                                        },
                                        {
                                            "name": "makeSN.driver_08-58-37.R",
                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/makeSN.driver_08-58-37.R",
                                            "size": 1091
                                        },
                                        {
                                            "name": "CGGA_RNAseq_325_grouplabels1_dataset_metrics_03-49-08.csv",
                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/CGGA_RNAseq_325_grouplabels1_dataset_metrics_03-49-08.csv",
                                            "size": 1217
                                        },
                                        {
                                            "name": "CGGA_RNAseq_325_grouplabels1_outliers_03-49-08.csv",
                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/CGGA_RNAseq_325_grouplabels1_outliers_03-49-08.csv",
                                            "size": 2028
                                        },
                                        {
                                            "name": "CGGA_RNAseq_325_makeSN_QA_log_08-58-37.csv",
                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/CGGA_RNAseq_325_makeSN_QA_log_08-58-37.csv",
                                            "size": 375
                                        },
                                        {
                                            "name": "CGGA_RNAseq_325_makeSN_sessionInfo_08-58-37.txt",
                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/CGGA_RNAseq_325_makeSN_sessionInfo_08-58-37.txt",
                                            "size": 7924
                                        }
                                    ],
                                    "children": [
                                        {
                                            "name": "B.1.1",
                                            "type": "Dataset",
                                            "attributes": {
                                                "Title": "CGGA RNA-seq 325 EXT OR",
                                                "Owner": [
                                                    "Rebecca Eliscu"
                                                ],
                                                "Analyst": [
                                                    "Rebecca Eliscu"
                                                ],
                                                "Availability": "Lab",
                                                "Analyte": [
                                                    "mRNA"
                                                ],
                                                "Technology": [
                                                    "Sequencer"
                                                ],
                                                "Platform": [
                                                    "Illumina HiSeq 2000"
                                                ],
                                                "Quantification Scale": "FPKM",
                                                "Number of Features": "24326",
                                                "Unique Identifier": "SYMBOL",
                                                "Transformations": "EXT OR",
                                                "Number of Samples": "315",
                                                "Number of Individuals": "315",
                                                "_id": "53a4027acade4785a485defe24453e59",
                                                "Omicon ID": "OMICON.DS.000401.B.1.1"
                                            },
                                            "files": [
                                                {
                                                    "name": "CGGA_RNAseq_325_All_315_outliers_removed.csv",
                                                    "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/CGGA_RNAseq_325_All_315_outliers_removed.csv",
                                                    "size": 38951422
                                                },
                                                {
                                                    "name": "CGGA_RNAseq_325_All_315_outliers_removed_DS_attributes.csv",
                                                    "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/CGGA_RNAseq_325_All_315_outliers_removed_DS_attributes.csv",
                                                    "size": 2216
                                                },
                                                {
                                                    "name": "CGGA_RNAseq_325_All_315_outliers_removed_sample_attributes.csv",
                                                    "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/CGGA_RNAseq_325_All_315_outliers_removed_sample_attributes.csv",
                                                    "size": 64369
                                                },
                                                {
                                                    "name": "CGGA_RNAseq_325_All_315_outliers_removed_feature_metadata.csv",
                                                    "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/CGGA_RNAseq_325_All_315_outliers_removed_feature_metadata.csv",
                                                    "size": 331260
                                                }
                                            ],
                                            "children": [
                                                {
                                                    "name": "QN",
                                                    "type": "transformation",
                                                    "attributes": {
                                                        "Analyst": [
                                                            "Rebecca Eliscu"
                                                        ],
                                                        "Owner": [
                                                            "Rebecca Eliscu"
                                                        ]
                                                    },
                                                    "files": [
                                                        {
                                                            "name": "All_change_by_round.pdf",
                                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/All_change_by_round.pdf",
                                                            "size": 5101
                                                        },
                                                        {
                                                            "name": "All_rd_1_pc1.pdf",
                                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/All_rd_1_pc1.pdf",
                                                            "size": 61619
                                                        },
                                                        {
                                                            "name": "All_rd_2_pc1.pdf",
                                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/All_rd_2_pc1.pdf",
                                                            "size": 61194
                                                        },
                                                        {
                                                            "name": "All_rd_3_pc1.pdf",
                                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/All_rd_3_pc1.pdf",
                                                            "size": 61411
                                                        },
                                                        {
                                                            "name": "All_rd_4_pc1.pdf",
                                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/All_rd_4_pc1.pdf",
                                                            "size": 61428
                                                        },
                                                        {
                                                            "name": "All_rd_5_pc1.pdf",
                                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/All_rd_5_pc1.pdf",
                                                            "size": 61006
                                                        },
                                                        {
                                                            "name": "All_rd_Qnorm_pc1.pdf",
                                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/All_rd_Qnorm_pc1.pdf",
                                                            "size": 60804
                                                        },
                                                        {
                                                            "name": "CGGA_RNAseq_325_All_315_final_sample_metrics.csv",
                                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/CGGA_RNAseq_325_All_315_final_sample_metrics.csv",
                                                            "size": 32551
                                                        },
                                                        {
                                                            "name": "SN_driver_03-49-08.R",
                                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/SN_driver_03-49-08.R",
                                                            "size": 2865
                                                        },
                                                        {
                                                            "name": "SampleNetwork_1.08.r",
                                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/SampleNetwork_1.08.r",
                                                            "size": 79461
                                                        },
                                                        {
                                                            "name": "makeSN.driver_08-58-37.R",
                                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/makeSN.driver_08-58-37.R",
                                                            "size": 1091
                                                        },
                                                        {
                                                            "name": "CGGA_RNAseq_325_grouplabels1_dataset_metrics_03-49-08.csv",
                                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/CGGA_RNAseq_325_grouplabels1_dataset_metrics_03-49-08.csv",
                                                            "size": 1217
                                                        },
                                                        {
                                                            "name": "CGGA_RNAseq_325_grouplabels1_outliers_03-49-08.csv",
                                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/CGGA_RNAseq_325_grouplabels1_outliers_03-49-08.csv",
                                                            "size": 2028
                                                        },
                                                        {
                                                            "name": "CGGA_RNAseq_325_makeSN_QA_log_08-58-37.csv",
                                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/CGGA_RNAseq_325_makeSN_QA_log_08-58-37.csv",
                                                            "size": 375
                                                        },
                                                        {
                                                            "name": "CGGA_RNAseq_325_makeSN_sessionInfo_08-58-37.txt",
                                                            "key": "CGGA_RNAseq_325_makeSN_08-58-37/CGGA_RNAseq_325_makeSN_sessionInfo_08-58-37.txt",
                                                            "size": 7924
                                                        }
                                                    ],
                                                    "children": [
                                                        {
                                                            "name": "B.1.1.1",
                                                            "type": "Dataset",
                                                            "attributes": {
                                                                "Title": "CGGA RNA-seq 325 EXT OR QN",
                                                                "Owner": [
                                                                    "Rebecca Eliscu"
                                                                ],
                                                                "Analyst": [
                                                                    "Rebecca Eliscu"
                                                                ],
                                                                "Availability": "Lab",
                                                                "Analyte": [
                                                                    "mRNA"
                                                                ],
                                                                "Technology": [
                                                                    "Sequencer"
                                                                ],
                                                                "Platform": [
                                                                    "Illumina HiSeq 2000"
                                                                ],
                                                                "Quantification Scale": "FPKM",
                                                                "Number of Features": "24326",
                                                                "Unique Identifier": "SYMBOL",
                                                                "Transformations": "EXT OR QN",
                                                                "Number of Samples": "315",
                                                                "Number of Individuals": "315",
                                                                "_id": "ba0aaad768c3418d9770d5b751a02b19",
                                                                "Omicon ID": "OMICON.DS.000401.B.1.1.1"
                                                            },
                                                            "files": [
                                                                {
                                                                    "name": "CGGA_RNAseq_325_All_315_Qnorm.csv",
                                                                    "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/CGGA_RNAseq_325_All_315_Qnorm.csv",
                                                                    "size": 131910640
                                                                },
                                                                {
                                                                    "name": "CGGA_RNAseq_325_All_315_Qnorm_DS_attributes.csv",
                                                                    "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/CGGA_RNAseq_325_All_315_Qnorm_DS_attributes.csv",
                                                                    "size": 2246
                                                                },
                                                                {
                                                                    "name": "CGGA_RNAseq_325_All_315_Qnorm_sample_attributes.csv",
                                                                    "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/CGGA_RNAseq_325_All_315_Qnorm_sample_attributes.csv",
                                                                    "size": 64369
                                                                },
                                                                {
                                                                    "name": "CGGA_RNAseq_325_All_315_Qnorm_feature_metadata.csv",
                                                                    "key": "CGGA_RNAseq_325_makeSN_08-58-37/All_03-49-08/CGGA_RNAseq_325_All_315_Qnorm_feature_metadata.csv",
                                                                    "size": 331260
                                                                }
                                                            ],
                                                            "children": [
                                                                {
                                                                    "name": "OR",
                                                                    "type": "transformation",
                                                                    "attributes": {
                                                                        "Analyst": [
                                                                            "Rebecca Eliscu"
                                                                        ],
                                                                        "Owner": [
                                                                            "Rebecca Eliscu"
                                                                        ]
                                                                    },
                                                                    "files": [
                                                                        {
                                                                            "name": "All_change_by_round.pdf",
                                                                            "key": "CGGA_RNAseq_325_makeSN_08-59-57/All_04-15-46/All_change_by_round.pdf",
                                                                            "size": 4995
                                                                        },
                                                                        {
                                                                            "name": "All_rd_1_pc1.pdf",
                                                                            "key": "CGGA_RNAseq_325_makeSN_08-59-57/All_04-15-46/All_rd_1_pc1.pdf",
                                                                            "size": 60804
                                                                        },
                                                                        {
                                                                            "name": "All_rd_2_pc1.pdf",
                                                                            "key": "CGGA_RNAseq_325_makeSN_08-59-57/All_04-15-46/All_rd_2_pc1.pdf",
                                                                            "size": 61060
                                                                        },
                                                                        {
                                                                            "name": "All_rd_3_pc1.pdf",
                                                                            "key": "CGGA_RNAseq_325_makeSN_08-59-57/All_04-15-46/All_rd_3_pc1.pdf",
                                                                            "size": 60524
                                                                        },
                                                                        {
                                                                            "name": "All_rd_4_pc1.pdf",
                                                                            "key": "CGGA_RNAseq_325_makeSN_08-59-57/All_04-15-46/All_rd_4_pc1.pdf",
                                                                            "size": 60562
                                                                        },
                                                                        {
                                                                            "name": "CGGA_RNAseq_325_pt2_All_312_final_sample_metrics.csv",
                                                                            "key": "CGGA_RNAseq_325_makeSN_08-59-57/All_04-15-46/CGGA_RNAseq_325_pt2_All_312_final_sample_metrics.csv",
                                                                            "size": 32202
                                                                        },
                                                                        {
                                                                            "name": "SN_driver_pt2_04-15-46.R",
                                                                            "key": "CGGA_RNAseq_325_makeSN_08-59-57/SN_driver_pt2_04-15-46.R",
                                                                            "size": 2619
                                                                        },
                                                                        {
                                                                            "name": "SampleNetwork_1.08.r",
                                                                            "key": "CGGA_RNAseq_325_makeSN_08-59-57/SampleNetwork_1.08.r",
                                                                            "size": 79461
                                                                        },
                                                                        {
                                                                            "name": "makeSN.driver_08-59-57.R",
                                                                            "key": "CGGA_RNAseq_325_makeSN_08-59-57/makeSN.driver_08-59-57.R",
                                                                            "size": 1111
                                                                        },
                                                                        {
                                                                            "name": "CGGA_RNAseq_325_pt2_grouplabels1_dataset_metrics_04-15-46.csv",
                                                                            "key": "CGGA_RNAseq_325_makeSN_08-59-57/CGGA_RNAseq_325_pt2_grouplabels1_dataset_metrics_04-15-46.csv",
                                                                            "size": 850
                                                                        },
                                                                        {
                                                                            "name": "CGGA_RNAseq_325_pt2_grouplabels1_outliers_04-15-46.csv",
                                                                            "key": "CGGA_RNAseq_325_makeSN_08-59-57/CGGA_RNAseq_325_pt2_grouplabels1_outliers_04-15-46.csv",
                                                                            "size": 1208
                                                                        },
                                                                        {
                                                                            "name": "CGGA_RNAseq_325_makeSN_QA_log_08-59-57.csv",
                                                                            "key": "CGGA_RNAseq_325_makeSN_08-59-57/CGGA_RNAseq_325_makeSN_QA_log_08-59-57.csv",
                                                                            "size": 375
                                                                        },
                                                                        {
                                                                            "name": "CGGA_RNAseq_325_makeSN_sessionInfo_08-59-57.txt",
                                                                            "key": "CGGA_RNAseq_325_makeSN_08-59-57/CGGA_RNAseq_325_makeSN_sessionInfo_08-59-57.txt",
                                                                            "size": 8099
                                                                        }
                                                                    ],
                                                                    "children": [
                                                                        {
                                                                            "name": "B.1.1.1.1",
                                                                            "type": "Dataset",
                                                                            "attributes": {
                                                                                "Title": "CGGA RNA-seq 325 EXT OR QN OR",
                                                                                "Owner": [
                                                                                    "Rebecca Eliscu"
                                                                                ],
                                                                                "Analyst": [
                                                                                    "Rebecca Eliscu"
                                                                                ],
                                                                                "Availability": "Lab",
                                                                                "Analyte": [
                                                                                    "mRNA"
                                                                                ],
                                                                                "Technology": [
                                                                                    "Sequencer"
                                                                                ],
                                                                                "Platform": [
                                                                                    "Illumina HiSeq 2000"
                                                                                ],
                                                                                "Quantification Scale": "FPKM",
                                                                                "Number of Features": "24326",
                                                                                "Unique Identifier": "SYMBOL",
                                                                                "Transformations": "EXT OR QN OR",
                                                                                "Number of Samples": "312",
                                                                                "Number of Individuals": "312",
                                                                                "_id": "3814e30944fe41b1ba5b50b8ff7c7ac8",
                                                                                "Omicon ID": "OMICON.DS.000401.B.1.1.1.1"
                                                                            },
                                                                            "files": [
                                                                                {
                                                                                    "name": "CGGA_RNAseq_325_pt2_All_312_outliers_removed.csv",
                                                                                    "key": "CGGA_RNAseq_325_makeSN_08-59-57/All_04-15-46/CGGA_RNAseq_325_pt2_All_312_outliers_removed.csv",
                                                                                    "size": 130648123
                                                                                },
                                                                                {
                                                                                    "name": "CGGA_RNAseq_325_pt2_All_312_outliers_removed_DS_attributes.csv",
                                                                                    "key": "CGGA_RNAseq_325_makeSN_08-59-57/All_04-15-46/CGGA_RNAseq_325_pt2_All_312_outliers_removed_DS_attributes.csv",
                                                                                    "size": 2308
                                                                                },
                                                                                {
                                                                                    "name": "CGGA_RNAseq_325_pt2_All_312_outliers_removed_sample_attributes.csv",
                                                                                    "key": "CGGA_RNAseq_325_makeSN_08-59-57/All_04-15-46/CGGA_RNAseq_325_pt2_All_312_outliers_removed_sample_attributes.csv",
                                                                                    "size": 63778
                                                                                },
                                                                                {
                                                                                    "name": "CGGA_RNAseq_325_pt2_All_312_outliers_removed_feature_metadata.csv",
                                                                                    "key": "CGGA_RNAseq_325_makeSN_08-59-57/All_04-15-46/CGGA_RNAseq_325_pt2_All_312_outliers_removed_feature_metadata.csv",
                                                                                    "size": 331260
                                                                                }
                                                                            ],
                                                                            "children": [
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.999,
                                                                                        "Signum": 0.6203552,
                                                                                        "MinSize": 20.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.999,
                                                                                                "Signum": 0.6203552,
                                                                                                "MinSize": 20.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 1.0,
                                                                                                "FinalModules": 1.0,
                                                                                                "Members": 20.0,
                                                                                                "MeanSpecificity": 0.0,
                                                                                                "MeanPC1VE": 0.7835737
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize20_merge_ME_0.85_24326/Modules_07-01-19.pdf",
                                                                                                    "name": "Modules_07-01-19.pdf",
                                                                                                    "size": 62899
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize20_merge_ME_0.85_24326/Module_statistics_07-01-19.pdf",
                                                                                                    "name": "Module_statistics_07-01-19.pdf",
                                                                                                    "size": 12415
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize20_merge_ME_0.85_24326/Module_statistics_07-01-19.csv",
                                                                                                    "name": "Module_statistics_07-01-19.csv",
                                                                                                    "size": 611
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize20_merge_ME_0.85_24326/Module_eigengenes_07-01-19.csv",
                                                                                                    "name": "Module_eigengenes_07-01-19.csv",
                                                                                                    "size": 11869
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize20_merge_ME_0.85_24326/kME_table_07-01-19.csv",
                                                                                                    "name": "kME_table_07-01-19.csv",
                                                                                                    "size": 922498
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-01-19_TOPMODPOSBC/GSHyperG_BROAD_SETS_07-01-19_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_07-01-19_TOPMODPOSBC.csv",
                                                                                                                    "size": 6318749
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-01-19_TOPMODPOSFDR/GSHyperG_BROAD_SETS_07-01-19_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_07-01-19_TOPMODPOSFDR.csv",
                                                                                                                    "size": 6692356
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-01-19_TOPMODPOSBC/GSHyperG_MY_SETS_07-01-19_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_07-01-19_TOPMODPOSBC.csv",
                                                                                                                    "size": 1495352
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-01-19_TOPMODPOSFDR/GSHyperG_MY_SETS_07-01-19_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_07-01-19_TOPMODPOSFDR.csv",
                                                                                                                    "size": 1795243
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.9999,
                                                                                        "Signum": 0.7398991,
                                                                                        "MinSize": 10.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.9999,
                                                                                                "Signum": 0.7398991,
                                                                                                "MinSize": 10.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 8.0,
                                                                                                "FinalModules": 2.0,
                                                                                                "Members": 92.0,
                                                                                                "MeanSpecificity": 99.639003,
                                                                                                "MeanPC1VE": 0.5062706
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize10_merge_ME_0.85_24326/Module_eigengenes_06-53-36.csv",
                                                                                                    "name": "Module_eigengenes_06-53-36.csv",
                                                                                                    "size": 17944
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize10_merge_ME_0.85_24326/Modules_06-53-36.pdf",
                                                                                                    "name": "Modules_06-53-36.pdf",
                                                                                                    "size": 185782
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize10_merge_ME_0.85_24326/Module_statistics_06-53-36.pdf",
                                                                                                    "name": "Module_statistics_06-53-36.pdf",
                                                                                                    "size": 13234
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize10_merge_ME_0.85_24326/Module_statistics_06-53-36.csv",
                                                                                                    "name": "Module_statistics_06-53-36.csv",
                                                                                                    "size": 978
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize10_merge_ME_0.85_24326/kME_table_06-53-36.csv",
                                                                                                    "name": "kME_table_06-53-36.csv",
                                                                                                    "size": 907181
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-53-36_TOPMODPOSBC/GSHyperG_BROAD_SETS_06-53-36_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_06-53-36_TOPMODPOSBC.csv",
                                                                                                                    "size": 6856494
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-53-36_TOPMODPOSFDR/GSHyperG_BROAD_SETS_06-53-36_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_06-53-36_TOPMODPOSFDR.csv",
                                                                                                                    "size": 7358482
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-53-36_TOPMODPOSBC/GSHyperG_MY_SETS_06-53-36_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_06-53-36_TOPMODPOSBC.csv",
                                                                                                                    "size": 1501542
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-53-36_TOPMODPOSFDR/GSHyperG_MY_SETS_06-53-36_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_06-53-36_TOPMODPOSFDR.csv",
                                                                                                                    "size": 2028661
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.9999,
                                                                                        "Signum": 0.7398991,
                                                                                        "MinSize": 12.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.9999,
                                                                                                "Signum": 0.7398991,
                                                                                                "MinSize": 12.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 3.0,
                                                                                                "FinalModules": 2.0,
                                                                                                "Members": 38.0,
                                                                                                "MeanSpecificity": 93.435521,
                                                                                                "MeanPC1VE": 0.8149493
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize12_merge_ME_0.85_24326/Module_statistics_06-53-58.pdf",
                                                                                                    "name": "Module_statistics_06-53-58.pdf",
                                                                                                    "size": 13395
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize12_merge_ME_0.85_24326/kME_table_06-53-58.csv",
                                                                                                    "name": "kME_table_06-53-58.csv",
                                                                                                    "size": 944045
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize12_merge_ME_0.85_24326/Module_statistics_06-53-58.csv",
                                                                                                    "name": "Module_statistics_06-53-58.csv",
                                                                                                    "size": 975
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize12_merge_ME_0.85_24326/Module_eigengenes_06-53-58.csv",
                                                                                                    "name": "Module_eigengenes_06-53-58.csv",
                                                                                                    "size": 17983
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize12_merge_ME_0.85_24326/Modules_06-53-58.pdf",
                                                                                                    "name": "Modules_06-53-58.pdf",
                                                                                                    "size": 116466
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-53-58_TOPMODPOSBC/GSHyperG_BROAD_SETS_06-53-58_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_06-53-58_TOPMODPOSBC.csv",
                                                                                                                    "size": 7141265
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-53-58_TOPMODPOSFDR/GSHyperG_BROAD_SETS_06-53-58_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_06-53-58_TOPMODPOSFDR.csv",
                                                                                                                    "size": 7366410
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-53-58_TOPMODPOSBC/GSHyperG_MY_SETS_06-53-58_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_06-53-58_TOPMODPOSBC.csv",
                                                                                                                    "size": 1723054
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-53-58_TOPMODPOSFDR/GSHyperG_MY_SETS_06-53-58_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_06-53-58_TOPMODPOSFDR.csv",
                                                                                                                    "size": 2051644
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.999,
                                                                                        "Signum": 0.6203552,
                                                                                        "MinSize": 15.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.999,
                                                                                                "Signum": 0.6203552,
                                                                                                "MinSize": 15.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 5.0,
                                                                                                "FinalModules": 3.0,
                                                                                                "Members": 88.0,
                                                                                                "MeanSpecificity": 96.437171,
                                                                                                "MeanPC1VE": 0.7185958
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize15_merge_ME_0.85_24326/kME_table_07-00-38.csv",
                                                                                                    "name": "kME_table_07-00-38.csv",
                                                                                                    "size": 1002519
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize15_merge_ME_0.85_24326/Modules_07-00-38.pdf",
                                                                                                    "name": "Modules_07-00-38.pdf",
                                                                                                    "size": 216969
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize15_merge_ME_0.85_24326/ME_network_07-00-38.pdf",
                                                                                                    "name": "ME_network_07-00-38.pdf",
                                                                                                    "size": 13518
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize15_merge_ME_0.85_24326/ME_correlations_07-00-38.pdf",
                                                                                                    "name": "ME_correlations_07-00-38.pdf",
                                                                                                    "size": 10439
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize15_merge_ME_0.85_24326/Module_statistics_07-00-38.csv",
                                                                                                    "name": "Module_statistics_07-00-38.csv",
                                                                                                    "size": 1399
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize15_merge_ME_0.85_24326/Module_eigengenes_07-00-38.csv",
                                                                                                    "name": "Module_eigengenes_07-00-38.csv",
                                                                                                    "size": 24082
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize15_merge_ME_0.85_24326/Module_statistics_07-00-38.pdf",
                                                                                                    "name": "Module_statistics_07-00-38.pdf",
                                                                                                    "size": 14830
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-00-38_TOPMODPOSBC/GSHyperG_BROAD_SETS_07-00-38_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_07-00-38_TOPMODPOSBC.csv",
                                                                                                                    "size": 7835302
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-00-38_TOPMODPOSBC/BroadGSHG_enrichment_by_set_07-00-38_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_07-00-38_TOPMODPOSBC.pdf",
                                                                                                                    "size": 4881244
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-00-38_TOPMODPOSFDR/GSHyperG_BROAD_SETS_07-00-38_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_07-00-38_TOPMODPOSFDR.csv",
                                                                                                                    "size": 7967443
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-00-38_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_07-00-38_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_07-00-38_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 3590648
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-00-38_TOPMODPOSBC/GSHyperG_MY_SETS_07-00-38_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_07-00-38_TOPMODPOSBC.csv",
                                                                                                                    "size": 2005251
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-00-38_TOPMODPOSBC/MyGSHG_enrichment_by_set_07-00-38_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_07-00-38_TOPMODPOSBC.pdf",
                                                                                                                    "size": 341314
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-00-38_TOPMODPOSFDR/GSHyperG_MY_SETS_07-00-38_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_07-00-38_TOPMODPOSFDR.csv",
                                                                                                                    "size": 2327863
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-00-38_TOPMODPOSFDR/MyGSHG_enrichment_by_set_07-00-38_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_07-00-38_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 268196
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.9999,
                                                                                        "Signum": 0.7398991,
                                                                                        "MinSize": 8.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.9999,
                                                                                                "Signum": 0.7398991,
                                                                                                "MinSize": 8.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 15.0,
                                                                                                "FinalModules": 3.0,
                                                                                                "Members": 151.0,
                                                                                                "MeanSpecificity": 99.437644,
                                                                                                "MeanPC1VE": 0.4116465
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize8_merge_ME_0.85_24326/ME_correlations_06-53-11.pdf",
                                                                                                    "name": "ME_correlations_06-53-11.pdf",
                                                                                                    "size": 10457
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize8_merge_ME_0.85_24326/ME_network_06-53-11.pdf",
                                                                                                    "name": "ME_network_06-53-11.pdf",
                                                                                                    "size": 13335
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize8_merge_ME_0.85_24326/Module_statistics_06-53-11.pdf",
                                                                                                    "name": "Module_statistics_06-53-11.pdf",
                                                                                                    "size": 15072
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize8_merge_ME_0.85_24326/Modules_06-53-11.pdf",
                                                                                                    "name": "Modules_06-53-11.pdf",
                                                                                                    "size": 293891
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize8_merge_ME_0.85_24326/kME_table_06-53-11.csv",
                                                                                                    "name": "kME_table_06-53-11.csv",
                                                                                                    "size": 925222
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize8_merge_ME_0.85_24326/Module_eigengenes_06-53-11.csv",
                                                                                                    "name": "Module_eigengenes_06-53-11.csv",
                                                                                                    "size": 23974
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize8_merge_ME_0.85_24326/Module_statistics_06-53-11.csv",
                                                                                                    "name": "Module_statistics_06-53-11.csv",
                                                                                                    "size": 1399
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-53-11_TOPMODPOSBC/GSHyperG_BROAD_SETS_06-53-11_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_06-53-11_TOPMODPOSBC.csv",
                                                                                                                    "size": 7198107
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-53-11_TOPMODPOSBC/BroadGSHG_enrichment_by_set_06-53-11_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_06-53-11_TOPMODPOSBC.pdf",
                                                                                                                    "size": 3303690
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-53-11_TOPMODPOSFDR/GSHyperG_BROAD_SETS_06-53-11_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_06-53-11_TOPMODPOSFDR.csv",
                                                                                                                    "size": 7914173
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-53-11_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_06-53-11_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_06-53-11_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 4212558
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-53-11_TOPMODPOSBC/GSHyperG_MY_SETS_06-53-11_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_06-53-11_TOPMODPOSBC.csv",
                                                                                                                    "size": 1397234
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-53-11_TOPMODPOSBC/MyGSHG_enrichment_by_set_06-53-11_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_06-53-11_TOPMODPOSBC.pdf",
                                                                                                                    "size": 191040
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-53-11_TOPMODPOSFDR/GSHyperG_MY_SETS_06-53-11_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_06-53-11_TOPMODPOSFDR.csv",
                                                                                                                    "size": 2135970
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.74_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-53-11_TOPMODPOSFDR/MyGSHG_enrichment_by_set_06-53-11_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_06-53-11_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 278080
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.99,
                                                                                        "Signum": 0.4586114,
                                                                                        "MinSize": 20.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.99,
                                                                                                "Signum": 0.4586114,
                                                                                                "MinSize": 20.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 26.0,
                                                                                                "FinalModules": 18.0,
                                                                                                "Members": 560.0,
                                                                                                "MeanSpecificity": 27.706142,
                                                                                                "MeanPC1VE": 0.4889826
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize20_merge_ME_0.85_24326/kME_table_07-30-36.csv",
                                                                                                    "name": "kME_table_07-30-36.csv",
                                                                                                    "size": 1099342
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize20_merge_ME_0.85_24326/Module_statistics_07-30-36.pdf",
                                                                                                    "name": "Module_statistics_07-30-36.pdf",
                                                                                                    "size": 25681
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize20_merge_ME_0.85_24326/ME_correlations_07-30-36.pdf",
                                                                                                    "name": "ME_correlations_07-30-36.pdf",
                                                                                                    "size": 200587
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize20_merge_ME_0.85_24326/Modules_07-30-36.pdf",
                                                                                                    "name": "Modules_07-30-36.pdf",
                                                                                                    "size": 1324002
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize20_merge_ME_0.85_24326/Module_eigengenes_07-30-36.csv",
                                                                                                    "name": "Module_eigengenes_07-30-36.csv",
                                                                                                    "size": 115241
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize20_merge_ME_0.85_24326/ME_network_07-30-36.pdf",
                                                                                                    "name": "ME_network_07-30-36.pdf",
                                                                                                    "size": 38047
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize20_merge_ME_0.85_24326/Module_statistics_07-30-36.csv",
                                                                                                    "name": "Module_statistics_07-30-36.csv",
                                                                                                    "size": 6392
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-30-36_TOPMODPOSBC/GSHyperG_BROAD_SETS_07-30-36_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_07-30-36_TOPMODPOSBC.csv",
                                                                                                                    "size": 12522678
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-30-36_TOPMODPOSBC/BroadGSHG_enrichment_by_set_07-30-36_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_07-30-36_TOPMODPOSBC.pdf",
                                                                                                                    "size": 5033272
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-30-36_TOPMODPOSFDR/GSHyperG_BROAD_SETS_07-30-36_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_07-30-36_TOPMODPOSFDR.csv",
                                                                                                                    "size": 13829664
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-30-36_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_07-30-36_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_07-30-36_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 4667550
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-30-36_TOPMODPOSBC/GSHyperG_MY_SETS_07-30-36_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_07-30-36_TOPMODPOSBC.csv",
                                                                                                                    "size": 2550204
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-30-36_TOPMODPOSBC/MyGSHG_enrichment_by_set_07-30-36_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_07-30-36_TOPMODPOSBC.pdf",
                                                                                                                    "size": 355720
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-30-36_TOPMODPOSFDR/GSHyperG_MY_SETS_07-30-36_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_07-30-36_TOPMODPOSFDR.csv",
                                                                                                                    "size": 2938750
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-30-36_TOPMODPOSFDR/MyGSHG_enrichment_by_set_07-30-36_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_07-30-36_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 335234
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.999,
                                                                                        "Signum": 0.6203552,
                                                                                        "MinSize": 12.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.999,
                                                                                                "Signum": 0.6203552,
                                                                                                "MinSize": 12.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 22.0,
                                                                                                "FinalModules": 18.0,
                                                                                                "Members": 307.0,
                                                                                                "MeanSpecificity": 26.290551,
                                                                                                "MeanPC1VE": 0.7785871
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize12_merge_ME_0.85_24326/Module_statistics_06-58-43.csv",
                                                                                                    "name": "Module_statistics_06-58-43.csv",
                                                                                                    "size": 6416
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize12_merge_ME_0.85_24326/kME_table_06-58-43.csv",
                                                                                                    "name": "kME_table_06-58-43.csv",
                                                                                                    "size": 1107091
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize12_merge_ME_0.85_24326/ME_network_06-58-43.pdf",
                                                                                                    "name": "ME_network_06-58-43.pdf",
                                                                                                    "size": 39088
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize12_merge_ME_0.85_24326/Module_statistics_06-58-43.pdf",
                                                                                                    "name": "Module_statistics_06-58-43.pdf",
                                                                                                    "size": 25786
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize12_merge_ME_0.85_24326/Modules_06-58-43.pdf",
                                                                                                    "name": "Modules_06-58-43.pdf",
                                                                                                    "size": 987420
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize12_merge_ME_0.85_24326/Module_eigengenes_06-58-43.csv",
                                                                                                    "name": "Module_eigengenes_06-58-43.csv",
                                                                                                    "size": 115935
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize12_merge_ME_0.85_24326/ME_correlations_06-58-43.pdf",
                                                                                                    "name": "ME_correlations_06-58-43.pdf",
                                                                                                    "size": 200467
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-58-43_TOPMODPOSBC/GSHyperG_BROAD_SETS_06-58-43_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_06-58-43_TOPMODPOSBC.csv",
                                                                                                                    "size": 13161241
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-58-43_TOPMODPOSBC/BroadGSHG_enrichment_by_set_06-58-43_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_06-58-43_TOPMODPOSBC.pdf",
                                                                                                                    "size": 4441037
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-58-43_TOPMODPOSFDR/GSHyperG_BROAD_SETS_06-58-43_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_06-58-43_TOPMODPOSFDR.csv",
                                                                                                                    "size": 13787497
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-58-43_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_06-58-43_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_06-58-43_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 3456779
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-58-43_TOPMODPOSBC/GSHyperG_MY_SETS_06-58-43_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_06-58-43_TOPMODPOSBC.csv",
                                                                                                                    "size": 2605931
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-58-43_TOPMODPOSBC/MyGSHG_enrichment_by_set_06-58-43_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_06-58-43_TOPMODPOSBC.pdf",
                                                                                                                    "size": 336442
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-58-43_TOPMODPOSFDR/GSHyperG_MY_SETS_06-58-43_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_06-58-43_TOPMODPOSFDR.csv",
                                                                                                                    "size": 2922589
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-58-43_TOPMODPOSFDR/MyGSHG_enrichment_by_set_06-58-43_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_06-58-43_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 324089
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.98,
                                                                                        "Signum": 0.398158,
                                                                                        "MinSize": 20.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.98,
                                                                                                "Signum": 0.398158,
                                                                                                "MinSize": 20.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 45.0,
                                                                                                "FinalModules": 20.0,
                                                                                                "Members": 1046.0,
                                                                                                "MeanSpecificity": 26.087937,
                                                                                                "MeanPC1VE": 0.3299115
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize20_merge_ME_0.85_24326/Module_statistics_08-27-06.csv",
                                                                                                    "name": "Module_statistics_08-27-06.csv",
                                                                                                    "size": 7029
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize20_merge_ME_0.85_24326/ME_network_08-27-06.pdf",
                                                                                                    "name": "ME_network_08-27-06.pdf",
                                                                                                    "size": 40376
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize20_merge_ME_0.85_24326/kME_table_08-27-06.csv",
                                                                                                    "name": "kME_table_08-27-06.csv",
                                                                                                    "size": 1127828
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize20_merge_ME_0.85_24326/Modules_08-27-06.pdf",
                                                                                                    "name": "Modules_08-27-06.pdf",
                                                                                                    "size": 2006174
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize20_merge_ME_0.85_24326/ME_correlations_08-27-06.pdf",
                                                                                                    "name": "ME_correlations_08-27-06.pdf",
                                                                                                    "size": 241566
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize20_merge_ME_0.85_24326/Module_eigengenes_08-27-06.csv",
                                                                                                    "name": "Module_eigengenes_08-27-06.csv",
                                                                                                    "size": 126691
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize20_merge_ME_0.85_24326/Module_statistics_08-27-06.pdf",
                                                                                                    "name": "Module_statistics_08-27-06.pdf",
                                                                                                    "size": 27561
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_08-27-06_TOPMODPOSBC/GSHyperG_BROAD_SETS_08-27-06_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_08-27-06_TOPMODPOSBC.csv",
                                                                                                                    "size": 12124576
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_08-27-06_TOPMODPOSBC/BroadGSHG_enrichment_by_set_08-27-06_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_08-27-06_TOPMODPOSBC.pdf",
                                                                                                                    "size": 6187857
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_08-27-06_TOPMODPOSFDR/GSHyperG_BROAD_SETS_08-27-06_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_08-27-06_TOPMODPOSFDR.csv",
                                                                                                                    "size": 13709011
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_08-27-06_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_08-27-06_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_08-27-06_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 5242110
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_08-27-06_TOPMODPOSBC/GSHyperG_MY_SETS_08-27-06_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_08-27-06_TOPMODPOSBC.csv",
                                                                                                                    "size": 2625364
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_08-27-06_TOPMODPOSBC/MyGSHG_enrichment_by_set_08-27-06_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_08-27-06_TOPMODPOSBC.pdf",
                                                                                                                    "size": 448491
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_08-27-06_TOPMODPOSFDR/GSHyperG_MY_SETS_08-27-06_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_08-27-06_TOPMODPOSFDR.csv",
                                                                                                                    "size": 2917606
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_08-27-06_TOPMODPOSFDR/MyGSHG_enrichment_by_set_08-27-06_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_08-27-06_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 418511
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.999,
                                                                                        "Signum": 0.6203552,
                                                                                        "MinSize": 10.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.999,
                                                                                                "Signum": 0.6203552,
                                                                                                "MinSize": 10.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 45.0,
                                                                                                "FinalModules": 20.0,
                                                                                                "Members": 549.0,
                                                                                                "MeanSpecificity": 24.480103,
                                                                                                "MeanPC1VE": 0.6587713
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize10_merge_ME_0.85_24326/ME_network_06-56-41.pdf",
                                                                                                    "name": "ME_network_06-56-41.pdf",
                                                                                                    "size": 40764
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize10_merge_ME_0.85_24326/Modules_06-56-41.pdf",
                                                                                                    "name": "Modules_06-56-41.pdf",
                                                                                                    "size": 1372168
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize10_merge_ME_0.85_24326/kME_table_06-56-41.csv",
                                                                                                    "name": "kME_table_06-56-41.csv",
                                                                                                    "size": 1098068
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize10_merge_ME_0.85_24326/Module_statistics_06-56-41.csv",
                                                                                                    "name": "Module_statistics_06-56-41.csv",
                                                                                                    "size": 7078
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize10_merge_ME_0.85_24326/Module_statistics_06-56-41.pdf",
                                                                                                    "name": "Module_statistics_06-56-41.pdf",
                                                                                                    "size": 27401
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize10_merge_ME_0.85_24326/ME_correlations_06-56-41.pdf",
                                                                                                    "name": "ME_correlations_06-56-41.pdf",
                                                                                                    "size": 240747
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize10_merge_ME_0.85_24326/Module_eigengenes_06-56-41.csv",
                                                                                                    "name": "Module_eigengenes_06-56-41.csv",
                                                                                                    "size": 127788
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-56-41_TOPMODPOSBC/GSHyperG_BROAD_SETS_06-56-41_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_06-56-41_TOPMODPOSBC.csv",
                                                                                                                    "size": 13604079
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-56-41_TOPMODPOSBC/BroadGSHG_enrichment_by_set_06-56-41_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_06-56-41_TOPMODPOSBC.pdf",
                                                                                                                    "size": 5354709
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-56-41_TOPMODPOSFDR/GSHyperG_BROAD_SETS_06-56-41_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_06-56-41_TOPMODPOSFDR.csv",
                                                                                                                    "size": 14488781
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-56-41_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_06-56-41_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_06-56-41_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 4319667
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-56-41_TOPMODPOSBC/GSHyperG_MY_SETS_06-56-41_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_06-56-41_TOPMODPOSBC.csv",
                                                                                                                    "size": 2680517
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-56-41_TOPMODPOSBC/MyGSHG_enrichment_by_set_06-56-41_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_06-56-41_TOPMODPOSBC.pdf",
                                                                                                                    "size": 347870
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-56-41_TOPMODPOSFDR/GSHyperG_MY_SETS_06-56-41_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_06-56-41_TOPMODPOSFDR.csv",
                                                                                                                    "size": 2997991
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-56-41_TOPMODPOSFDR/MyGSHG_enrichment_by_set_06-56-41_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_06-56-41_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 293696
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.97,
                                                                                        "Signum": 0.3595226,
                                                                                        "MinSize": 20.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.97,
                                                                                                "Signum": 0.3595226,
                                                                                                "MinSize": 20.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 65.0,
                                                                                                "FinalModules": 31.0,
                                                                                                "Members": 1500.0,
                                                                                                "MeanSpecificity": 19.340406,
                                                                                                "MeanPC1VE": 0.3209632
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize20_merge_ME_0.85_24326/ME_correlations_09-53-24.pdf",
                                                                                                    "name": "ME_correlations_09-53-24.pdf",
                                                                                                    "size": 549530
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize20_merge_ME_0.85_24326/ME_network_09-53-24.pdf",
                                                                                                    "name": "ME_network_09-53-24.pdf",
                                                                                                    "size": 59316
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize20_merge_ME_0.85_24326/Module_statistics_09-53-24.pdf",
                                                                                                    "name": "Module_statistics_09-53-24.pdf",
                                                                                                    "size": 35107
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize20_merge_ME_0.85_24326/Module_eigengenes_09-53-24.csv",
                                                                                                    "name": "Module_eigengenes_09-53-24.csv",
                                                                                                    "size": 193644
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize20_merge_ME_0.85_24326/kME_table_09-53-24.csv",
                                                                                                    "name": "kME_table_09-53-24.csv",
                                                                                                    "size": 1179462
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize20_merge_ME_0.85_24326/Modules_09-53-24.pdf",
                                                                                                    "name": "Modules_09-53-24.pdf",
                                                                                                    "size": 2952875
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize20_merge_ME_0.85_24326/Module_statistics_09-53-24.csv",
                                                                                                    "name": "Module_statistics_09-53-24.csv",
                                                                                                    "size": 10743
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-53-24_TOPMODPOSBC/GSHyperG_BROAD_SETS_09-53-24_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_09-53-24_TOPMODPOSBC.csv",
                                                                                                                    "size": 14645377
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-53-24_TOPMODPOSBC/BroadGSHG_enrichment_by_set_09-53-24_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_09-53-24_TOPMODPOSBC.pdf",
                                                                                                                    "size": 7960862
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-53-24_TOPMODPOSFDR/GSHyperG_BROAD_SETS_09-53-24_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_09-53-24_TOPMODPOSFDR.csv",
                                                                                                                    "size": 16049145
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-53-24_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_09-53-24_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_09-53-24_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 7262272
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-53-24_TOPMODPOSBC/GSHyperG_MY_SETS_09-53-24_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_09-53-24_TOPMODPOSBC.csv",
                                                                                                                    "size": 2887912
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-53-24_TOPMODPOSBC/MyGSHG_enrichment_by_set_09-53-24_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_09-53-24_TOPMODPOSBC.pdf",
                                                                                                                    "size": 522322
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-53-24_TOPMODPOSFDR/GSHyperG_MY_SETS_09-53-24_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_09-53-24_TOPMODPOSFDR.csv",
                                                                                                                    "size": 3163712
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-53-24_TOPMODPOSFDR/MyGSHG_enrichment_by_set_09-53-24_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_09-53-24_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 507347
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.999,
                                                                                        "Signum": 0.6203552,
                                                                                        "MinSize": 8.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.999,
                                                                                                "Signum": 0.6203552,
                                                                                                "MinSize": 8.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 88.0,
                                                                                                "FinalModules": 33.0,
                                                                                                "Members": 915.0,
                                                                                                "MeanSpecificity": 19.471023,
                                                                                                "MeanPC1VE": 0.6045253
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize8_merge_ME_0.85_24326/ME_correlations_06-54-21.pdf",
                                                                                                    "name": "ME_correlations_06-54-21.pdf",
                                                                                                    "size": 617395
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize8_merge_ME_0.85_24326/kME_table_06-54-21.csv",
                                                                                                    "name": "kME_table_06-54-21.csv",
                                                                                                    "size": 1143812
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize8_merge_ME_0.85_24326/Module_statistics_06-54-21.csv",
                                                                                                    "name": "Module_statistics_06-54-21.csv",
                                                                                                    "size": 11439
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize8_merge_ME_0.85_24326/Module_statistics_06-54-21.pdf",
                                                                                                    "name": "Module_statistics_06-54-21.pdf",
                                                                                                    "size": 36435
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize8_merge_ME_0.85_24326/ME_network_06-54-21.pdf",
                                                                                                    "name": "ME_network_06-54-21.pdf",
                                                                                                    "size": 61981
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize8_merge_ME_0.85_24326/Module_eigengenes_06-54-21.csv",
                                                                                                    "name": "Module_eigengenes_06-54-21.csv",
                                                                                                    "size": 206835
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize8_merge_ME_0.85_24326/Modules_06-54-21.pdf",
                                                                                                    "name": "Modules_06-54-21.pdf",
                                                                                                    "size": 2282572
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-54-21_TOPMODPOSBC/GSHyperG_BROAD_SETS_06-54-21_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_06-54-21_TOPMODPOSBC.csv",
                                                                                                                    "size": 16575414
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-54-21_TOPMODPOSBC/BroadGSHG_enrichment_by_set_06-54-21_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_06-54-21_TOPMODPOSBC.pdf",
                                                                                                                    "size": 7702589
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-54-21_TOPMODPOSFDR/GSHyperG_BROAD_SETS_06-54-21_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_06-54-21_TOPMODPOSFDR.csv",
                                                                                                                    "size": 17466195
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_06-54-21_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_06-54-21_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_06-54-21_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 5859071
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-54-21_TOPMODPOSBC/GSHyperG_MY_SETS_06-54-21_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_06-54-21_TOPMODPOSBC.csv",
                                                                                                                    "size": 2999877
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-54-21_TOPMODPOSBC/MyGSHG_enrichment_by_set_06-54-21_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_06-54-21_TOPMODPOSBC.pdf",
                                                                                                                    "size": 481014
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-54-21_TOPMODPOSFDR/GSHyperG_MY_SETS_06-54-21_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_06-54-21_TOPMODPOSFDR.csv",
                                                                                                                    "size": 3275508
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.62_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_06-54-21_TOPMODPOSFDR/MyGSHG_enrichment_by_set_06-54-21_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_06-54-21_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 410299
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.99,
                                                                                        "Signum": 0.4586114,
                                                                                        "MinSize": 15.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.99,
                                                                                                "Signum": 0.4586114,
                                                                                                "MinSize": 15.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 64.0,
                                                                                                "FinalModules": 35.0,
                                                                                                "Members": 1193.0,
                                                                                                "MeanSpecificity": null,
                                                                                                "MeanPC1VE": 0.4787734
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize15_merge_ME_0.85_24326/ME_correlations_07-27-29.pdf",
                                                                                                    "name": "ME_correlations_07-27-29.pdf",
                                                                                                    "size": 690555
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize15_merge_ME_0.85_24326/Module_statistics_07-27-29.csv",
                                                                                                    "name": "Module_statistics_07-27-29.csv",
                                                                                                    "size": 12085
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize15_merge_ME_0.85_24326/kME_table_07-27-29.csv",
                                                                                                    "name": "kME_table_07-27-29.csv",
                                                                                                    "size": 1169799
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize15_merge_ME_0.85_24326/Module_statistics_07-27-29.pdf",
                                                                                                    "name": "Module_statistics_07-27-29.pdf",
                                                                                                    "size": 37931
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize15_merge_ME_0.85_24326/Modules_07-27-29.pdf",
                                                                                                    "name": "Modules_07-27-29.pdf",
                                                                                                    "size": 2713329
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize15_merge_ME_0.85_24326/ME_network_07-27-29.pdf",
                                                                                                    "name": "ME_network_07-27-29.pdf",
                                                                                                    "size": 66073
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize15_merge_ME_0.85_24326/Module_eigengenes_07-27-29.csv",
                                                                                                    "name": "Module_eigengenes_07-27-29.csv",
                                                                                                    "size": 218545
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-27-29_TOPMODPOSBC/GSHyperG_BROAD_SETS_07-27-29_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_07-27-29_TOPMODPOSBC.csv",
                                                                                                                    "size": 16822304
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-27-29_TOPMODPOSBC/BroadGSHG_enrichment_by_set_07-27-29_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_07-27-29_TOPMODPOSBC.pdf",
                                                                                                                    "size": 6301492
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-27-29_TOPMODPOSFDR/GSHyperG_BROAD_SETS_07-27-29_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_07-27-29_TOPMODPOSFDR.csv",
                                                                                                                    "size": 17917968
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-27-29_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_07-27-29_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_07-27-29_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 5584018
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-27-29_TOPMODPOSBC/GSHyperG_MY_SETS_07-27-29_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_07-27-29_TOPMODPOSBC.csv",
                                                                                                                    "size": 3072993
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-27-29_TOPMODPOSBC/MyGSHG_enrichment_by_set_07-27-29_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_07-27-29_TOPMODPOSBC.pdf",
                                                                                                                    "size": 426856
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-27-29_TOPMODPOSFDR/GSHyperG_MY_SETS_07-27-29_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_07-27-29_TOPMODPOSFDR.csv",
                                                                                                                    "size": 3329073
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-27-29_TOPMODPOSFDR/MyGSHG_enrichment_by_set_07-27-29_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_07-27-29_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 386839
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.96,
                                                                                        "Signum": 0.330181,
                                                                                        "MinSize": 20.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.96,
                                                                                                "Signum": 0.330181,
                                                                                                "MinSize": 20.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 78.0,
                                                                                                "FinalModules": 40.0,
                                                                                                "Members": 1822.0,
                                                                                                "MeanSpecificity": 15.653572,
                                                                                                "MeanPC1VE": 0.3121187
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize20_merge_ME_0.85_24326/kME_table_10-44-19.csv",
                                                                                                    "name": "kME_table_10-44-19.csv",
                                                                                                    "size": 1202113
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize20_merge_ME_0.85_24326/Module_statistics_10-44-19.pdf",
                                                                                                    "name": "Module_statistics_10-44-19.pdf",
                                                                                                    "size": 41713
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize20_merge_ME_0.85_24326/Module_eigengenes_10-44-19.csv",
                                                                                                    "name": "Module_eigengenes_10-44-19.csv",
                                                                                                    "size": 247915
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize20_merge_ME_0.85_24326/Modules_10-44-19.pdf",
                                                                                                    "name": "Modules_10-44-19.pdf",
                                                                                                    "size": 3669619
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize20_merge_ME_0.85_24326/ME_correlations_10-44-19.pdf",
                                                                                                    "name": "ME_correlations_10-44-19.pdf",
                                                                                                    "size": 879838
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize20_merge_ME_0.85_24326/Module_statistics_10-44-19.csv",
                                                                                                    "name": "Module_statistics_10-44-19.csv",
                                                                                                    "size": 13747
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize20_merge_ME_0.85_24326/ME_network_10-44-19.pdf",
                                                                                                    "name": "ME_network_10-44-19.pdf",
                                                                                                    "size": 72472
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_10-44-19_TOPMODPOSBC/GSHyperG_BROAD_SETS_10-44-19_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_10-44-19_TOPMODPOSBC.csv",
                                                                                                                    "size": 16445797
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_10-44-19_TOPMODPOSBC/BroadGSHG_enrichment_by_set_10-44-19_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_10-44-19_TOPMODPOSBC.pdf",
                                                                                                                    "size": 8873617
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_10-44-19_TOPMODPOSFDR/GSHyperG_BROAD_SETS_10-44-19_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_10-44-19_TOPMODPOSFDR.csv",
                                                                                                                    "size": 17906102
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_10-44-19_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_10-44-19_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_10-44-19_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 8377684
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_10-44-19_TOPMODPOSBC/GSHyperG_MY_SETS_10-44-19_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_10-44-19_TOPMODPOSBC.csv",
                                                                                                                    "size": 3109576
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_10-44-19_TOPMODPOSBC/MyGSHG_enrichment_by_set_10-44-19_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_10-44-19_TOPMODPOSBC.pdf",
                                                                                                                    "size": 596372
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_10-44-19_TOPMODPOSFDR/GSHyperG_MY_SETS_10-44-19_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_10-44-19_TOPMODPOSFDR.csv",
                                                                                                                    "size": 3363165
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_10-44-19_TOPMODPOSFDR/MyGSHG_enrichment_by_set_10-44-19_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_10-44-19_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 577551
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.95,
                                                                                        "Signum": 0.3069247,
                                                                                        "MinSize": 20.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.95,
                                                                                                "Signum": 0.3069247,
                                                                                                "MinSize": 20.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 99.0,
                                                                                                "FinalModules": 50.0,
                                                                                                "Members": 2355.0,
                                                                                                "MeanSpecificity": 15.86193,
                                                                                                "MeanPC1VE": 0.3010873
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize20_merge_ME_0.85_24326/ME_network_11-44-10.pdf",
                                                                                                    "name": "ME_network_11-44-10.pdf",
                                                                                                    "size": 88925
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize20_merge_ME_0.85_24326/Module_statistics_11-44-10.csv",
                                                                                                    "name": "Module_statistics_11-44-10.csv",
                                                                                                    "size": 17054
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize20_merge_ME_0.85_24326/Module_statistics_11-44-10.pdf",
                                                                                                    "name": "Module_statistics_11-44-10.pdf",
                                                                                                    "size": 48096
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize20_merge_ME_0.85_24326/Modules_11-44-10.pdf",
                                                                                                    "name": "Modules_11-44-10.pdf",
                                                                                                    "size": 4701557
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize20_merge_ME_0.85_24326/kME_table_11-44-10.csv",
                                                                                                    "name": "kME_table_11-44-10.csv",
                                                                                                    "size": 1232042
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize20_merge_ME_0.85_24326/Module_eigengenes_11-44-10.csv",
                                                                                                    "name": "Module_eigengenes_11-44-10.csv",
                                                                                                    "size": 308393
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize20_merge_ME_0.85_24326/ME_correlations_11-44-10.pdf",
                                                                                                    "name": "ME_correlations_11-44-10.pdf",
                                                                                                    "size": 1358368
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_11-44-10_TOPMODPOSBC/GSHyperG_BROAD_SETS_11-44-10_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_11-44-10_TOPMODPOSBC.csv",
                                                                                                                    "size": 17897334
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_11-44-10_TOPMODPOSBC/BroadGSHG_enrichment_by_set_11-44-10_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_11-44-10_TOPMODPOSBC.pdf",
                                                                                                                    "size": 9182739
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_11-44-10_TOPMODPOSFDR/GSHyperG_BROAD_SETS_11-44-10_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_11-44-10_TOPMODPOSFDR.csv",
                                                                                                                    "size": 19325081
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize20_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_11-44-10_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_11-44-10_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_11-44-10_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 8869793
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_11-44-10_TOPMODPOSBC/GSHyperG_MY_SETS_11-44-10_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_11-44-10_TOPMODPOSBC.csv",
                                                                                                                    "size": 3292090
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_11-44-10_TOPMODPOSBC/MyGSHG_enrichment_by_set_11-44-10_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_11-44-10_TOPMODPOSBC.pdf",
                                                                                                                    "size": 598502
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_11-44-10_TOPMODPOSFDR/GSHyperG_MY_SETS_11-44-10_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_11-44-10_TOPMODPOSFDR.csv",
                                                                                                                    "size": 3550283
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize20_merge_ME_0.85_24326/GSHyperG_MY_SETS_11-44-10_TOPMODPOSFDR/MyGSHG_enrichment_by_set_11-44-10_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_11-44-10_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 587203
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.99,
                                                                                        "Signum": 0.4586114,
                                                                                        "MinSize": 12.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.99,
                                                                                                "Signum": 0.4586114,
                                                                                                "MinSize": 12.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 126.0,
                                                                                                "FinalModules": 51.0,
                                                                                                "Members": 1992.0,
                                                                                                "MeanSpecificity": 12.632032,
                                                                                                "MeanPC1VE": 0.4298676
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize12_merge_ME_0.85_24326/kME_table_07-22-56.csv",
                                                                                                    "name": "kME_table_07-22-56.csv",
                                                                                                    "size": 1201908
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize12_merge_ME_0.85_24326/ME_network_07-22-56.pdf",
                                                                                                    "name": "ME_network_07-22-56.pdf",
                                                                                                    "size": 93247
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize12_merge_ME_0.85_24326/Modules_07-22-56.pdf",
                                                                                                    "name": "Modules_07-22-56.pdf",
                                                                                                    "size": 4274696
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize12_merge_ME_0.85_24326/Module_eigengenes_07-22-56.csv",
                                                                                                    "name": "Module_eigengenes_07-22-56.csv",
                                                                                                    "size": 315477
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize12_merge_ME_0.85_24326/ME_correlations_07-22-56.pdf",
                                                                                                    "name": "ME_correlations_07-22-56.pdf",
                                                                                                    "size": 1406093
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize12_merge_ME_0.85_24326/Module_statistics_07-22-56.csv",
                                                                                                    "name": "Module_statistics_07-22-56.csv",
                                                                                                    "size": 17423
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize12_merge_ME_0.85_24326/Module_statistics_07-22-56.pdf",
                                                                                                    "name": "Module_statistics_07-22-56.pdf",
                                                                                                    "size": 48395
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-22-56_TOPMODPOSBC/GSHyperG_BROAD_SETS_07-22-56_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_07-22-56_TOPMODPOSBC.csv",
                                                                                                                    "size": 19308863
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-22-56_TOPMODPOSBC/BroadGSHG_enrichment_by_set_07-22-56_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_07-22-56_TOPMODPOSBC.pdf",
                                                                                                                    "size": 9549338
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-22-56_TOPMODPOSFDR/GSHyperG_BROAD_SETS_07-22-56_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_07-22-56_TOPMODPOSFDR.csv",
                                                                                                                    "size": 20755714
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-22-56_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_07-22-56_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_07-22-56_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 8954640
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-22-56_TOPMODPOSBC/GSHyperG_MY_SETS_07-22-56_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_07-22-56_TOPMODPOSBC.csv",
                                                                                                                    "size": 3354190
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-22-56_TOPMODPOSBC/MyGSHG_enrichment_by_set_07-22-56_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_07-22-56_TOPMODPOSBC.pdf",
                                                                                                                    "size": 578087
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-22-56_TOPMODPOSFDR/GSHyperG_MY_SETS_07-22-56_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_07-22-56_TOPMODPOSFDR.csv",
                                                                                                                    "size": 3617894
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-22-56_TOPMODPOSFDR/MyGSHG_enrichment_by_set_07-22-56_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_07-22-56_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 562185
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.98,
                                                                                        "Signum": 0.398158,
                                                                                        "MinSize": 15.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.98,
                                                                                                "Signum": 0.398158,
                                                                                                "MinSize": 15.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 121.0,
                                                                                                "FinalModules": 52.0,
                                                                                                "Members": 2293.0,
                                                                                                "MeanSpecificity": 12.808844,
                                                                                                "MeanPC1VE": 0.3722851
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize15_merge_ME_0.85_24326/Module_statistics_08-22-04.pdf",
                                                                                                    "name": "Module_statistics_08-22-04.pdf",
                                                                                                    "size": 49305
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize15_merge_ME_0.85_24326/ME_correlations_08-22-04.pdf",
                                                                                                    "name": "ME_correlations_08-22-04.pdf",
                                                                                                    "size": 1456492
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize15_merge_ME_0.85_24326/Module_eigengenes_08-22-04.csv",
                                                                                                    "name": "Module_eigengenes_08-22-04.csv",
                                                                                                    "size": 321077
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize15_merge_ME_0.85_24326/Modules_08-22-04.pdf",
                                                                                                    "name": "Modules_08-22-04.pdf",
                                                                                                    "size": 4701784
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize15_merge_ME_0.85_24326/ME_network_08-22-04.pdf",
                                                                                                    "name": "ME_network_08-22-04.pdf",
                                                                                                    "size": 94701
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize15_merge_ME_0.85_24326/kME_table_08-22-04.csv",
                                                                                                    "name": "kME_table_08-22-04.csv",
                                                                                                    "size": 1211798
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize15_merge_ME_0.85_24326/Module_statistics_08-22-04.csv",
                                                                                                    "name": "Module_statistics_08-22-04.csv",
                                                                                                    "size": 17757
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_08-22-04_TOPMODPOSBC/GSHyperG_BROAD_SETS_08-22-04_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_08-22-04_TOPMODPOSBC.csv",
                                                                                                                    "size": 19222740
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_08-22-04_TOPMODPOSBC/BroadGSHG_enrichment_by_set_08-22-04_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_08-22-04_TOPMODPOSBC.pdf",
                                                                                                                    "size": 8342251
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_08-22-04_TOPMODPOSFDR/GSHyperG_BROAD_SETS_08-22-04_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_08-22-04_TOPMODPOSFDR.csv",
                                                                                                                    "size": 20920651
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_08-22-04_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_08-22-04_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_08-22-04_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 7922209
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_08-22-04_TOPMODPOSBC/GSHyperG_MY_SETS_08-22-04_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_08-22-04_TOPMODPOSBC.csv",
                                                                                                                    "size": 3349175
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_08-22-04_TOPMODPOSBC/MyGSHG_enrichment_by_set_08-22-04_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_08-22-04_TOPMODPOSBC.pdf",
                                                                                                                    "size": 507597
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_08-22-04_TOPMODPOSFDR/GSHyperG_MY_SETS_08-22-04_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_08-22-04_TOPMODPOSFDR.csv",
                                                                                                                    "size": 3644644
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_08-22-04_TOPMODPOSFDR/MyGSHG_enrichment_by_set_08-22-04_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_08-22-04_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 505038
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.97,
                                                                                        "Signum": 0.3595226,
                                                                                        "MinSize": 15.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.97,
                                                                                                "Signum": 0.3595226,
                                                                                                "MinSize": 15.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 159.0,
                                                                                                "FinalModules": 75.0,
                                                                                                "Members": 3051.0,
                                                                                                "MeanSpecificity": 9.012228,
                                                                                                "MeanPC1VE": 0.3673979
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize15_merge_ME_0.85_24326/Module_eigengenes_09-46-58.csv",
                                                                                                    "name": "Module_eigengenes_09-46-58.csv",
                                                                                                    "size": 461084
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize15_merge_ME_0.85_24326/Modules_09-46-58.pdf",
                                                                                                    "name": "Modules_09-46-58.pdf",
                                                                                                    "size": 6451014
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize15_merge_ME_0.85_24326/Module_statistics_09-46-58.pdf",
                                                                                                    "name": "Module_statistics_09-46-58.pdf",
                                                                                                    "size": 60860
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize15_merge_ME_0.85_24326/ME_network_09-46-58.pdf",
                                                                                                    "name": "ME_network_09-46-58.pdf",
                                                                                                    "size": 130130
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize15_merge_ME_0.85_24326/Module_statistics_09-46-58.csv",
                                                                                                    "name": "Module_statistics_09-46-58.csv",
                                                                                                    "size": 25374
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize15_merge_ME_0.85_24326/kME_table_09-46-58.csv",
                                                                                                    "name": "kME_table_09-46-58.csv",
                                                                                                    "size": 1253715
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize15_merge_ME_0.85_24326/ME_correlations_09-46-58.pdf",
                                                                                                    "name": "ME_correlations_09-46-58.pdf",
                                                                                                    "size": 2913089
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-46-58_TOPMODPOSBC/GSHyperG_BROAD_SETS_09-46-58_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_09-46-58_TOPMODPOSBC.csv",
                                                                                                                    "size": 23163375
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-46-58_TOPMODPOSBC/BroadGSHG_enrichment_by_set_09-46-58_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_09-46-58_TOPMODPOSBC.pdf",
                                                                                                                    "size": 9967751
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-46-58_TOPMODPOSFDR/GSHyperG_BROAD_SETS_09-46-58_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_09-46-58_TOPMODPOSFDR.csv",
                                                                                                                    "size": 25149477
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-46-58_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_09-46-58_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_09-46-58_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 9980396
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-46-58_TOPMODPOSBC/GSHyperG_MY_SETS_09-46-58_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_09-46-58_TOPMODPOSBC.csv",
                                                                                                                    "size": 3712131
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-46-58_TOPMODPOSBC/MyGSHG_enrichment_by_set_09-46-58_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_09-46-58_TOPMODPOSBC.pdf",
                                                                                                                    "size": 654351
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-46-58_TOPMODPOSFDR/GSHyperG_MY_SETS_09-46-58_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_09-46-58_TOPMODPOSFDR.csv",
                                                                                                                    "size": 4056949
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-46-58_TOPMODPOSFDR/MyGSHG_enrichment_by_set_09-46-58_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_09-46-58_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 670597
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.98,
                                                                                        "Signum": 0.398158,
                                                                                        "MinSize": 12.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.98,
                                                                                                "Signum": 0.398158,
                                                                                                "MinSize": 12.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 196.0,
                                                                                                "FinalModules": 82.0,
                                                                                                "Members": 3260.0,
                                                                                                "MeanSpecificity": null,
                                                                                                "MeanPC1VE": 0.3901339
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize12_merge_ME_0.85_24326/Module_statistics_08-14-05.csv",
                                                                                                    "name": "Module_statistics_08-14-05.csv",
                                                                                                    "size": 27757
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize12_merge_ME_0.85_24326/kME_table_08-14-05.csv",
                                                                                                    "name": "kME_table_08-14-05.csv",
                                                                                                    "size": 1247378
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize12_merge_ME_0.85_24326/Module_eigengenes_08-14-05.csv",
                                                                                                    "name": "Module_eigengenes_08-14-05.csv",
                                                                                                    "size": 503616
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize12_merge_ME_0.85_24326/ME_correlations_08-14-05.pdf",
                                                                                                    "name": "ME_correlations_08-14-05.pdf",
                                                                                                    "size": 3443974
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize12_merge_ME_0.85_24326/ME_network_08-14-05.pdf",
                                                                                                    "name": "ME_network_08-14-05.pdf",
                                                                                                    "size": 138794
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize12_merge_ME_0.85_24326/Modules_08-14-05.pdf",
                                                                                                    "name": "Modules_08-14-05.pdf",
                                                                                                    "size": 6948543
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize12_merge_ME_0.85_24326/Module_statistics_08-14-05.pdf",
                                                                                                    "name": "Module_statistics_08-14-05.pdf",
                                                                                                    "size": 64578
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_08-14-05_TOPMODPOSBC/GSHyperG_BROAD_SETS_08-14-05_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_08-14-05_TOPMODPOSBC.csv",
                                                                                                                    "size": 24734959
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_08-14-05_TOPMODPOSBC/BroadGSHG_enrichment_by_set_08-14-05_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_08-14-05_TOPMODPOSBC.pdf",
                                                                                                                    "size": 10432329
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_08-14-05_TOPMODPOSFDR/GSHyperG_BROAD_SETS_08-14-05_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_08-14-05_TOPMODPOSFDR.csv",
                                                                                                                    "size": 26500509
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_08-14-05_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_08-14-05_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_08-14-05_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 9870926
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_08-14-05_TOPMODPOSBC/GSHyperG_MY_SETS_08-14-05_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_08-14-05_TOPMODPOSBC.csv",
                                                                                                                    "size": 3889442
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_08-14-05_TOPMODPOSBC/MyGSHG_enrichment_by_set_08-14-05_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_08-14-05_TOPMODPOSBC.pdf",
                                                                                                                    "size": 649143
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_08-14-05_TOPMODPOSFDR/GSHyperG_MY_SETS_08-14-05_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_08-14-05_TOPMODPOSFDR.csv",
                                                                                                                    "size": 4192352
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_08-14-05_TOPMODPOSFDR/MyGSHG_enrichment_by_set_08-14-05_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_08-14-05_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 667325
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.99,
                                                                                        "Signum": 0.4586114,
                                                                                        "MinSize": 10.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.99,
                                                                                                "Signum": 0.4586114,
                                                                                                "MinSize": 10.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 188.0,
                                                                                                "FinalModules": 84.0,
                                                                                                "Members": 2636.0,
                                                                                                "MeanSpecificity": 8.485863,
                                                                                                "MeanPC1VE": 0.4454987
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize10_merge_ME_0.85_24326/Module_eigengenes_07-14-39.csv",
                                                                                                    "name": "Module_eigengenes_07-14-39.csv",
                                                                                                    "size": 516175
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize10_merge_ME_0.85_24326/kME_table_07-14-39.csv",
                                                                                                    "name": "kME_table_07-14-39.csv",
                                                                                                    "size": 1251655
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize10_merge_ME_0.85_24326/Module_statistics_07-14-39.pdf",
                                                                                                    "name": "Module_statistics_07-14-39.pdf",
                                                                                                    "size": 65669
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize10_merge_ME_0.85_24326/Module_statistics_07-14-39.csv",
                                                                                                    "name": "Module_statistics_07-14-39.csv",
                                                                                                    "size": 28402
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize10_merge_ME_0.85_24326/ME_correlations_07-14-39.pdf",
                                                                                                    "name": "ME_correlations_07-14-39.pdf",
                                                                                                    "size": 3625420
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize10_merge_ME_0.85_24326/Modules_07-14-39.pdf",
                                                                                                    "name": "Modules_07-14-39.pdf",
                                                                                                    "size": 6206252
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize10_merge_ME_0.85_24326/ME_network_07-14-39.pdf",
                                                                                                    "name": "ME_network_07-14-39.pdf",
                                                                                                    "size": 139528
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-14-39_TOPMODPOSBC/GSHyperG_BROAD_SETS_07-14-39_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_07-14-39_TOPMODPOSBC.csv",
                                                                                                                    "size": 25899835
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-14-39_TOPMODPOSBC/BroadGSHG_enrichment_by_set_07-14-39_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_07-14-39_TOPMODPOSBC.pdf",
                                                                                                                    "size": 11657918
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-14-39_TOPMODPOSFDR/GSHyperG_BROAD_SETS_07-14-39_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_07-14-39_TOPMODPOSFDR.csv",
                                                                                                                    "size": 27472347
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-14-39_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_07-14-39_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_07-14-39_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 11840539
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-14-39_TOPMODPOSBC/GSHyperG_MY_SETS_07-14-39_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_07-14-39_TOPMODPOSBC.csv",
                                                                                                                    "size": 3973335
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-14-39_TOPMODPOSBC/MyGSHG_enrichment_by_set_07-14-39_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_07-14-39_TOPMODPOSBC.pdf",
                                                                                                                    "size": 692302
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-14-39_TOPMODPOSFDR/GSHyperG_MY_SETS_07-14-39_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_07-14-39_TOPMODPOSFDR.csv",
                                                                                                                    "size": 4245611
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-14-39_TOPMODPOSFDR/MyGSHG_enrichment_by_set_07-14-39_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_07-14-39_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 722232
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.96,
                                                                                        "Signum": 0.330181,
                                                                                        "MinSize": 15.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.96,
                                                                                                "Signum": 0.330181,
                                                                                                "MinSize": 15.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 182.0,
                                                                                                "FinalModules": 93.0,
                                                                                                "Members": 3553.0,
                                                                                                "MeanSpecificity": 8.751132,
                                                                                                "MeanPC1VE": 0.3747875
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize15_merge_ME_0.85_24326/ME_network_10-36-10.pdf",
                                                                                                    "name": "ME_network_10-36-10.pdf",
                                                                                                    "size": 154992
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize15_merge_ME_0.85_24326/Module_eigengenes_10-36-10.csv",
                                                                                                    "name": "Module_eigengenes_10-36-10.csv",
                                                                                                    "size": 570243
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize15_merge_ME_0.85_24326/ME_correlations_10-36-10.pdf",
                                                                                                    "name": "ME_correlations_10-36-10.pdf",
                                                                                                    "size": 4379581
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize15_merge_ME_0.85_24326/Module_statistics_10-36-10.pdf",
                                                                                                    "name": "Module_statistics_10-36-10.pdf",
                                                                                                    "size": 70809
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize15_merge_ME_0.85_24326/Modules_10-36-10.pdf",
                                                                                                    "name": "Modules_10-36-10.pdf",
                                                                                                    "size": 7691976
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize15_merge_ME_0.85_24326/kME_table_10-36-10.csv",
                                                                                                    "name": "kME_table_10-36-10.csv",
                                                                                                    "size": 1264292
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize15_merge_ME_0.85_24326/Module_statistics_10-36-10.csv",
                                                                                                    "name": "Module_statistics_10-36-10.csv",
                                                                                                    "size": 31474
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_10-36-10_TOPMODPOSBC/GSHyperG_BROAD_SETS_10-36-10_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_10-36-10_TOPMODPOSBC.csv",
                                                                                                                    "size": 26539895
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_10-36-10_TOPMODPOSBC/BroadGSHG_enrichment_by_set_10-36-10_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_10-36-10_TOPMODPOSBC.pdf",
                                                                                                                    "size": 12603439
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_10-36-10_TOPMODPOSFDR/GSHyperG_BROAD_SETS_10-36-10_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_10-36-10_TOPMODPOSFDR.csv",
                                                                                                                    "size": 28191936
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_10-36-10_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_10-36-10_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_10-36-10_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 12622683
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_10-36-10_TOPMODPOSBC/GSHyperG_MY_SETS_10-36-10_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_10-36-10_TOPMODPOSBC.csv",
                                                                                                                    "size": 4071118
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_10-36-10_TOPMODPOSBC/MyGSHG_enrichment_by_set_10-36-10_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_10-36-10_TOPMODPOSBC.pdf",
                                                                                                                    "size": 762660
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_10-36-10_TOPMODPOSFDR/GSHyperG_MY_SETS_10-36-10_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_10-36-10_TOPMODPOSFDR.csv",
                                                                                                                    "size": 4366065
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_10-36-10_TOPMODPOSFDR/MyGSHG_enrichment_by_set_10-36-10_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_10-36-10_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 805814
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.95,
                                                                                        "Signum": 0.3069247,
                                                                                        "MinSize": 15.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.95,
                                                                                                "Signum": 0.3069247,
                                                                                                "MinSize": 15.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 217.0,
                                                                                                "FinalModules": 114.0,
                                                                                                "Members": 4325.0,
                                                                                                "MeanSpecificity": null,
                                                                                                "MeanPC1VE": 0.3747865
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize15_merge_ME_0.85_24326/Module_statistics_11-34-04.pdf",
                                                                                                    "name": "Module_statistics_11-34-04.pdf",
                                                                                                    "size": 83167
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize15_merge_ME_0.85_24326/Module_eigengenes_11-34-04.csv",
                                                                                                    "name": "Module_eigengenes_11-34-04.csv",
                                                                                                    "size": 698361
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize15_merge_ME_0.85_24326/ME_correlations_11-34-04.pdf",
                                                                                                    "name": "ME_correlations_11-34-04.pdf",
                                                                                                    "size": 6396163
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize15_merge_ME_0.85_24326/ME_network_11-34-04.pdf",
                                                                                                    "name": "ME_network_11-34-04.pdf",
                                                                                                    "size": 189793
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize15_merge_ME_0.85_24326/Module_statistics_11-34-04.csv",
                                                                                                    "name": "Module_statistics_11-34-04.csv",
                                                                                                    "size": 38424
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize15_merge_ME_0.85_24326/Modules_11-34-04.pdf",
                                                                                                    "name": "Modules_11-34-04.pdf",
                                                                                                    "size": 9422288
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize15_merge_ME_0.85_24326/kME_table_11-34-04.csv",
                                                                                                    "name": "kME_table_11-34-04.csv",
                                                                                                    "size": 1255399
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_11-34-04_TOPMODPOSBC/GSHyperG_BROAD_SETS_11-34-04_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_11-34-04_TOPMODPOSBC.csv",
                                                                                                                    "size": 29682715
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_11-34-04_TOPMODPOSBC/BroadGSHG_enrichment_by_set_11-34-04_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_11-34-04_TOPMODPOSBC.pdf",
                                                                                                                    "size": 13188001
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_11-34-04_TOPMODPOSFDR/GSHyperG_BROAD_SETS_11-34-04_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_11-34-04_TOPMODPOSFDR.csv",
                                                                                                                    "size": 31774794
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize15_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_11-34-04_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_11-34-04_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_11-34-04_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 13714962
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_11-34-04_TOPMODPOSBC/GSHyperG_MY_SETS_11-34-04_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_11-34-04_TOPMODPOSBC.csv",
                                                                                                                    "size": 4335222
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_11-34-04_TOPMODPOSBC/MyGSHG_enrichment_by_set_11-34-04_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_11-34-04_TOPMODPOSBC.pdf",
                                                                                                                    "size": 803728
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_11-34-04_TOPMODPOSFDR/GSHyperG_MY_SETS_11-34-04_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_11-34-04_TOPMODPOSFDR.csv",
                                                                                                                    "size": 4707311
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize15_merge_ME_0.85_24326/GSHyperG_MY_SETS_11-34-04_TOPMODPOSFDR/MyGSHG_enrichment_by_set_11-34-04_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_11-34-04_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 851057
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.97,
                                                                                        "Signum": 0.3595226,
                                                                                        "MinSize": 12.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.97,
                                                                                                "Signum": 0.3595226,
                                                                                                "MinSize": 12.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 256.0,
                                                                                                "FinalModules": 130.0,
                                                                                                "Members": 4287.0,
                                                                                                "MeanSpecificity": 6.148927,
                                                                                                "MeanPC1VE": 0.4092036
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize12_merge_ME_0.85_24326/ME_correlations_09-33-16.pdf",
                                                                                                    "name": "ME_correlations_09-33-16.pdf",
                                                                                                    "size": 8264532
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize12_merge_ME_0.85_24326/Module_eigengenes_09-33-16.csv",
                                                                                                    "name": "Module_eigengenes_09-33-16.csv",
                                                                                                    "size": 796076
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize12_merge_ME_0.85_24326/ME_network_09-33-16.pdf",
                                                                                                    "name": "ME_network_09-33-16.pdf",
                                                                                                    "size": 211404
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize12_merge_ME_0.85_24326/kME_table_09-33-16.csv",
                                                                                                    "name": "kME_table_09-33-16.csv",
                                                                                                    "size": 1269934
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize12_merge_ME_0.85_24326/Modules_09-33-16.pdf",
                                                                                                    "name": "Modules_09-33-16.pdf",
                                                                                                    "size": 9877013
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize12_merge_ME_0.85_24326/Module_statistics_09-33-16.pdf",
                                                                                                    "name": "Module_statistics_09-33-16.pdf",
                                                                                                    "size": 93016
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize12_merge_ME_0.85_24326/Module_statistics_09-33-16.csv",
                                                                                                    "name": "Module_statistics_09-33-16.csv",
                                                                                                    "size": 43795
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-33-16_TOPMODPOSBC/GSHyperG_BROAD_SETS_09-33-16_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_09-33-16_TOPMODPOSBC.csv",
                                                                                                                    "size": 32712759
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-33-16_TOPMODPOSBC/BroadGSHG_enrichment_by_set_09-33-16_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_09-33-16_TOPMODPOSBC.pdf",
                                                                                                                    "size": 14019138
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-33-16_TOPMODPOSFDR/GSHyperG_BROAD_SETS_09-33-16_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_09-33-16_TOPMODPOSFDR.csv",
                                                                                                                    "size": 34907095
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-33-16_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_09-33-16_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_09-33-16_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 12219878
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-33-16_TOPMODPOSBC/GSHyperG_MY_SETS_09-33-16_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_09-33-16_TOPMODPOSBC.csv",
                                                                                                                    "size": 4600196
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-33-16_TOPMODPOSBC/MyGSHG_enrichment_by_set_09-33-16_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_09-33-16_TOPMODPOSBC.pdf",
                                                                                                                    "size": 804526
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-33-16_TOPMODPOSFDR/GSHyperG_MY_SETS_09-33-16_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_09-33-16_TOPMODPOSFDR.csv",
                                                                                                                    "size": 5009984
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-33-16_TOPMODPOSFDR/MyGSHG_enrichment_by_set_09-33-16_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_09-33-16_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 710376
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.99,
                                                                                        "Signum": 0.4586114,
                                                                                        "MinSize": 8.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.99,
                                                                                                "Signum": 0.4586114,
                                                                                                "MinSize": 8.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 294.0,
                                                                                                "FinalModules": 150.0,
                                                                                                "Members": 3525.0,
                                                                                                "MeanSpecificity": 6.166543,
                                                                                                "MeanPC1VE": 0.5004357
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize8_merge_ME_0.85_24326/ME_correlations_07-01-50.pdf",
                                                                                                    "name": "ME_correlations_07-01-50.pdf",
                                                                                                    "size": 10600382
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize8_merge_ME_0.85_24326/Module_statistics_07-01-50.pdf",
                                                                                                    "name": "Module_statistics_07-01-50.pdf",
                                                                                                    "size": 101156
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize8_merge_ME_0.85_24326/kME_table_07-01-50.csv",
                                                                                                    "name": "kME_table_07-01-50.csv",
                                                                                                    "size": 1280337
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize8_merge_ME_0.85_24326/ME_network_07-01-50.pdf",
                                                                                                    "name": "ME_network_07-01-50.pdf",
                                                                                                    "size": 246967
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize8_merge_ME_0.85_24326/Module_statistics_07-01-50.csv",
                                                                                                    "name": "Module_statistics_07-01-50.csv",
                                                                                                    "size": 50485
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize8_merge_ME_0.85_24326/Modules_07-01-50.pdf",
                                                                                                    "name": "Modules_07-01-50.pdf",
                                                                                                    "size": 9564550
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize8_merge_ME_0.85_24326/Module_eigengenes_07-01-50.csv",
                                                                                                    "name": "Module_eigengenes_07-01-50.csv",
                                                                                                    "size": 918705
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-01-50_TOPMODPOSBC/GSHyperG_BROAD_SETS_07-01-50_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_07-01-50_TOPMODPOSBC.csv",
                                                                                                                    "size": 36379521
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-01-50_TOPMODPOSBC/BroadGSHG_enrichment_by_set_07-01-50_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_07-01-50_TOPMODPOSBC.pdf",
                                                                                                                    "size": 11740396
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-01-50_TOPMODPOSFDR/GSHyperG_BROAD_SETS_07-01-50_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_07-01-50_TOPMODPOSFDR.csv",
                                                                                                                    "size": 38086522
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-01-50_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_07-01-50_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_07-01-50_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 12545654
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-01-50_TOPMODPOSBC/GSHyperG_MY_SETS_07-01-50_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_07-01-50_TOPMODPOSBC.csv",
                                                                                                                    "size": 5020641
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-01-50_TOPMODPOSBC/MyGSHG_enrichment_by_set_07-01-50_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_07-01-50_TOPMODPOSBC.pdf",
                                                                                                                    "size": 802371
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-01-50_TOPMODPOSFDR/GSHyperG_MY_SETS_07-01-50_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_07-01-50_TOPMODPOSFDR.csv",
                                                                                                                    "size": 5313758
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.459_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-01-50_TOPMODPOSFDR/MyGSHG_enrichment_by_set_07-01-50_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_07-01-50_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 874453
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.98,
                                                                                        "Signum": 0.398158,
                                                                                        "MinSize": 10.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.98,
                                                                                                "Signum": 0.398158,
                                                                                                "MinSize": 10.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 302.0,
                                                                                                "FinalModules": 152.0,
                                                                                                "Members": 4359.0,
                                                                                                "MeanSpecificity": null,
                                                                                                "MeanPC1VE": 0.4521265
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize10_merge_ME_0.85_24326/Module_eigengenes_07-57-54.csv",
                                                                                                    "name": "Module_eigengenes_07-57-54.csv",
                                                                                                    "size": 929718
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize10_merge_ME_0.85_24326/ME_correlations_07-57-54.pdf",
                                                                                                    "name": "ME_correlations_07-57-54.pdf",
                                                                                                    "size": 11056296
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize10_merge_ME_0.85_24326/ME_network_07-57-54.pdf",
                                                                                                    "name": "ME_network_07-57-54.pdf",
                                                                                                    "size": 241640
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize10_merge_ME_0.85_24326/Module_statistics_07-57-54.pdf",
                                                                                                    "name": "Module_statistics_07-57-54.pdf",
                                                                                                    "size": 103088
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize10_merge_ME_0.85_24326/Modules_07-57-54.pdf",
                                                                                                    "name": "Modules_07-57-54.pdf",
                                                                                                    "size": 10710048
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize10_merge_ME_0.85_24326/kME_table_07-57-54.csv",
                                                                                                    "name": "kME_table_07-57-54.csv",
                                                                                                    "size": 1281716
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize10_merge_ME_0.85_24326/Module_statistics_07-57-54.csv",
                                                                                                    "name": "Module_statistics_07-57-54.csv",
                                                                                                    "size": 51172
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-57-54_TOPMODPOSBC/GSHyperG_BROAD_SETS_07-57-54_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_07-57-54_TOPMODPOSBC.csv",
                                                                                                                    "size": 36396853
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-57-54_TOPMODPOSBC/BroadGSHG_enrichment_by_set_07-57-54_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_07-57-54_TOPMODPOSBC.pdf",
                                                                                                                    "size": 13011685
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-57-54_TOPMODPOSFDR/GSHyperG_BROAD_SETS_07-57-54_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_07-57-54_TOPMODPOSFDR.csv",
                                                                                                                    "size": 38437264
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-57-54_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_07-57-54_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_07-57-54_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 13619067
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-57-54_TOPMODPOSBC/GSHyperG_MY_SETS_07-57-54_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_07-57-54_TOPMODPOSBC.csv",
                                                                                                                    "size": 4986733
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-57-54_TOPMODPOSBC/MyGSHG_enrichment_by_set_07-57-54_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_07-57-54_TOPMODPOSBC.pdf",
                                                                                                                    "size": 815402
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-57-54_TOPMODPOSFDR/GSHyperG_MY_SETS_07-57-54_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_07-57-54_TOPMODPOSFDR.csv",
                                                                                                                    "size": 5351670
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-57-54_TOPMODPOSFDR/MyGSHG_enrichment_by_set_07-57-54_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_07-57-54_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 864957
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.96,
                                                                                        "Signum": 0.330181,
                                                                                        "MinSize": 12.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.96,
                                                                                                "Signum": 0.330181,
                                                                                                "MinSize": 12.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 314.0,
                                                                                                "FinalModules": 172.0,
                                                                                                "Members": 5247.0,
                                                                                                "MeanSpecificity": 5.172353,
                                                                                                "MeanPC1VE": 0.4098405
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize12_merge_ME_0.85_24326/Module_eigengenes_10-17-46.csv",
                                                                                                    "name": "Module_eigengenes_10-17-46.csv",
                                                                                                    "size": 1051704
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize12_merge_ME_0.85_24326/kME_table_10-17-46.csv",
                                                                                                    "name": "kME_table_10-17-46.csv",
                                                                                                    "size": 1279509
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize12_merge_ME_0.85_24326/Module_statistics_10-17-46.pdf",
                                                                                                    "name": "Module_statistics_10-17-46.pdf",
                                                                                                    "size": 114187
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize12_merge_ME_0.85_24326/Module_statistics_10-17-46.csv",
                                                                                                    "name": "Module_statistics_10-17-46.csv",
                                                                                                    "size": 57890
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize12_merge_ME_0.85_24326/ME_network_10-17-46.pdf",
                                                                                                    "name": "ME_network_10-17-46.pdf",
                                                                                                    "size": 274736
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize12_merge_ME_0.85_24326/ME_correlations_10-17-46.pdf",
                                                                                                    "name": "ME_correlations_10-17-46.pdf",
                                                                                                    "size": 13942048
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize12_merge_ME_0.85_24326/Modules_10-17-46.pdf",
                                                                                                    "name": "Modules_10-17-46.pdf",
                                                                                                    "size": 12520639
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_10-17-46_TOPMODPOSBC/GSHyperG_BROAD_SETS_10-17-46_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_10-17-46_TOPMODPOSBC.csv",
                                                                                                                    "size": 38286267
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_10-17-46_TOPMODPOSBC/BroadGSHG_enrichment_by_set_10-17-46_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_10-17-46_TOPMODPOSBC.pdf",
                                                                                                                    "size": 14335864
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_10-17-46_TOPMODPOSFDR/GSHyperG_BROAD_SETS_10-17-46_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_10-17-46_TOPMODPOSFDR.csv",
                                                                                                                    "size": 40498751
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_10-17-46_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_10-17-46_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_10-17-46_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 14783838
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_10-17-46_TOPMODPOSBC/GSHyperG_MY_SETS_10-17-46_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_10-17-46_TOPMODPOSBC.csv",
                                                                                                                    "size": 5225539
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_10-17-46_TOPMODPOSBC/MyGSHG_enrichment_by_set_10-17-46_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_10-17-46_TOPMODPOSBC.pdf",
                                                                                                                    "size": 944010
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_10-17-46_TOPMODPOSFDR/GSHyperG_MY_SETS_10-17-46_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_10-17-46_TOPMODPOSFDR.csv",
                                                                                                                    "size": 5622911
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_10-17-46_TOPMODPOSFDR/MyGSHG_enrichment_by_set_10-17-46_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_10-17-46_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 986320
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.95,
                                                                                        "Signum": 0.3069247,
                                                                                        "MinSize": 12.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.95,
                                                                                                "Signum": 0.3069247,
                                                                                                "MinSize": 12.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 366.0,
                                                                                                "FinalModules": 211.0,
                                                                                                "Members": 6240.0,
                                                                                                "MeanSpecificity": 4.567749,
                                                                                                "MeanPC1VE": 0.4042092
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize12_merge_ME_0.85_24326/ME_correlations_11-13-11.pdf",
                                                                                                    "name": "ME_correlations_11-13-11.pdf",
                                                                                                    "size": 20258950
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize12_merge_ME_0.85_24326/Module_eigengenes_11-13-11.csv",
                                                                                                    "name": "Module_eigengenes_11-13-11.csv",
                                                                                                    "size": 1288901
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize12_merge_ME_0.85_24326/kME_table_11-13-11.csv",
                                                                                                    "name": "kME_table_11-13-11.csv",
                                                                                                    "size": 1291553
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize12_merge_ME_0.85_24326/Module_statistics_11-13-11.pdf",
                                                                                                    "name": "Module_statistics_11-13-11.pdf",
                                                                                                    "size": 133247
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize12_merge_ME_0.85_24326/Modules_11-13-11.pdf",
                                                                                                    "name": "Modules_11-13-11.pdf",
                                                                                                    "size": 15100773
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize12_merge_ME_0.85_24326/Module_statistics_11-13-11.csv",
                                                                                                    "name": "Module_statistics_11-13-11.csv",
                                                                                                    "size": 70873
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize12_merge_ME_0.85_24326/ME_network_11-13-11.pdf",
                                                                                                    "name": "ME_network_11-13-11.pdf",
                                                                                                    "size": 335576
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_11-13-11_TOPMODPOSBC/GSHyperG_BROAD_SETS_11-13-11_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_11-13-11_TOPMODPOSBC.csv",
                                                                                                                    "size": 42402748
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_11-13-11_TOPMODPOSBC/BroadGSHG_enrichment_by_set_11-13-11_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_11-13-11_TOPMODPOSBC.pdf",
                                                                                                                    "size": 16673451
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_11-13-11_TOPMODPOSFDR/GSHyperG_BROAD_SETS_11-13-11_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_11-13-11_TOPMODPOSFDR.csv",
                                                                                                                    "size": 45102102
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize12_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_11-13-11_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_11-13-11_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_11-13-11_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 17701467
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_11-13-11_TOPMODPOSBC/GSHyperG_MY_SETS_11-13-11_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_11-13-11_TOPMODPOSBC.csv",
                                                                                                                    "size": 5721715
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_11-13-11_TOPMODPOSBC/MyGSHG_enrichment_by_set_11-13-11_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_11-13-11_TOPMODPOSBC.pdf",
                                                                                                                    "size": 1054065
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_11-13-11_TOPMODPOSFDR/GSHyperG_MY_SETS_11-13-11_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_11-13-11_TOPMODPOSFDR.csv",
                                                                                                                    "size": 6204441
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize12_merge_ME_0.85_24326/GSHyperG_MY_SETS_11-13-11_TOPMODPOSFDR/MyGSHG_enrichment_by_set_11-13-11_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_11-13-11_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 1117556
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.97,
                                                                                        "Signum": 0.3595226,
                                                                                        "MinSize": 10.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.97,
                                                                                                "Signum": 0.3595226,
                                                                                                "MinSize": 10.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 387.0,
                                                                                                "FinalModules": 227.0,
                                                                                                "Members": 5645.0,
                                                                                                "MeanSpecificity": 4.737158,
                                                                                                "MeanPC1VE": 0.4612318
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize10_merge_ME_0.85_24326/ME_network_09-05-29.pdf",
                                                                                                    "name": "ME_network_09-05-29.pdf",
                                                                                                    "size": 360415
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize10_merge_ME_0.85_24326/Module_statistics_09-05-29.csv",
                                                                                                    "name": "Module_statistics_09-05-29.csv",
                                                                                                    "size": 76358
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize10_merge_ME_0.85_24326/Module_eigengenes_09-05-29.csv",
                                                                                                    "name": "Module_eigengenes_09-05-29.csv",
                                                                                                    "size": 1387907
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize10_merge_ME_0.85_24326/Module_statistics_09-05-29.pdf",
                                                                                                    "name": "Module_statistics_09-05-29.pdf",
                                                                                                    "size": 142163
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize10_merge_ME_0.85_24326/ME_correlations_09-05-29.pdf",
                                                                                                    "name": "ME_correlations_09-05-29.pdf",
                                                                                                    "size": 22934903
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize10_merge_ME_0.85_24326/Modules_09-05-29.pdf",
                                                                                                    "name": "Modules_09-05-29.pdf",
                                                                                                    "size": 14871833
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize10_merge_ME_0.85_24326/kME_table_09-05-29.csv",
                                                                                                    "name": "kME_table_09-05-29.csv",
                                                                                                    "size": 1296409
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-05-29_TOPMODPOSBC/GSHyperG_BROAD_SETS_09-05-29_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_09-05-29_TOPMODPOSBC.csv",
                                                                                                                    "size": 45765744
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-05-29_TOPMODPOSBC/BroadGSHG_enrichment_by_set_09-05-29_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_09-05-29_TOPMODPOSBC.pdf",
                                                                                                                    "size": 16281164
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-05-29_TOPMODPOSFDR/GSHyperG_BROAD_SETS_09-05-29_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_09-05-29_TOPMODPOSFDR.csv",
                                                                                                                    "size": 48328889
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-05-29_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_09-05-29_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_09-05-29_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 16824265
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-05-29_TOPMODPOSBC/GSHyperG_MY_SETS_09-05-29_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_09-05-29_TOPMODPOSBC.csv",
                                                                                                                    "size": 6015819
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-05-29_TOPMODPOSBC/MyGSHG_enrichment_by_set_09-05-29_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_09-05-29_TOPMODPOSBC.pdf",
                                                                                                                    "size": 956087
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-05-29_TOPMODPOSFDR/GSHyperG_MY_SETS_09-05-29_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_09-05-29_TOPMODPOSFDR.csv",
                                                                                                                    "size": 6485255
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-05-29_TOPMODPOSFDR/MyGSHG_enrichment_by_set_09-05-29_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_09-05-29_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 998682
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.96,
                                                                                        "Signum": 0.330181,
                                                                                        "MinSize": 10.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.96,
                                                                                                "Signum": 0.330181,
                                                                                                "MinSize": 10.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 458.0,
                                                                                                "FinalModules": 275.0,
                                                                                                "Members": 6739.0,
                                                                                                "MeanSpecificity": null,
                                                                                                "MeanPC1VE": 0.4415094
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize10_merge_ME_0.85_24326/ME_network_09-55-59.pdf",
                                                                                                    "name": "ME_network_09-55-59.pdf",
                                                                                                    "size": 433206
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize10_merge_ME_0.85_24326/Module_statistics_09-55-59.csv",
                                                                                                    "name": "Module_statistics_09-55-59.csv",
                                                                                                    "size": 92351
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize10_merge_ME_0.85_24326/Modules_09-55-59.pdf",
                                                                                                    "name": "Modules_09-55-59.pdf",
                                                                                                    "size": 17901631
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize10_merge_ME_0.85_24326/kME_table_09-55-59.csv",
                                                                                                    "name": "kME_table_09-55-59.csv",
                                                                                                    "size": 1318941
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize10_merge_ME_0.85_24326/ME_correlations_09-55-59.pdf",
                                                                                                    "name": "ME_correlations_09-55-59.pdf",
                                                                                                    "size": 33512279
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize10_merge_ME_0.85_24326/Module_statistics_09-55-59.pdf",
                                                                                                    "name": "Module_statistics_09-55-59.pdf",
                                                                                                    "size": 165023
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize10_merge_ME_0.85_24326/Module_eigengenes_09-55-59.csv",
                                                                                                    "name": "Module_eigengenes_09-55-59.csv",
                                                                                                    "size": 1680093
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-55-59_TOPMODPOSBC/GSHyperG_BROAD_SETS_09-55-59_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_09-55-59_TOPMODPOSBC.csv",
                                                                                                                    "size": 50386378
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-55-59_TOPMODPOSBC/BroadGSHG_enrichment_by_set_09-55-59_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_09-55-59_TOPMODPOSBC.pdf",
                                                                                                                    "size": 16696658
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-55-59_TOPMODPOSFDR/GSHyperG_BROAD_SETS_09-55-59_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_09-55-59_TOPMODPOSFDR.csv",
                                                                                                                    "size": 52817542
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_09-55-59_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_09-55-59_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_09-55-59_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 17205377
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-55-59_TOPMODPOSBC/GSHyperG_MY_SETS_09-55-59_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_09-55-59_TOPMODPOSBC.csv",
                                                                                                                    "size": 6653661
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-55-59_TOPMODPOSBC/MyGSHG_enrichment_by_set_09-55-59_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_09-55-59_TOPMODPOSBC.pdf",
                                                                                                                    "size": 1042111
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-55-59_TOPMODPOSFDR/GSHyperG_MY_SETS_09-55-59_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_09-55-59_TOPMODPOSFDR.csv",
                                                                                                                    "size": 7093135
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.33_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_09-55-59_TOPMODPOSFDR/MyGSHG_enrichment_by_set_09-55-59_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_09-55-59_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 1071269
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.98,
                                                                                        "Signum": 0.398158,
                                                                                        "MinSize": 8.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.98,
                                                                                                "Signum": 0.398158,
                                                                                                "MinSize": 8.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 467.0,
                                                                                                "FinalModules": 279.0,
                                                                                                "Members": 5747.0,
                                                                                                "MeanSpecificity": 4.22848,
                                                                                                "MeanPC1VE": 0.4957863
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize8_merge_ME_0.85_24326/ME_correlations_07-32-33.pdf",
                                                                                                    "name": "ME_correlations_07-32-33.pdf",
                                                                                                    "size": 34448322
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize8_merge_ME_0.85_24326/Module_eigengenes_07-32-33.csv",
                                                                                                    "name": "Module_eigengenes_07-32-33.csv",
                                                                                                    "size": 1704818
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize8_merge_ME_0.85_24326/Modules_07-32-33.pdf",
                                                                                                    "name": "Modules_07-32-33.pdf",
                                                                                                    "size": 16783304
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize8_merge_ME_0.85_24326/ME_network_07-32-33.pdf",
                                                                                                    "name": "ME_network_07-32-33.pdf",
                                                                                                    "size": 428259
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize8_merge_ME_0.85_24326/Module_statistics_07-32-33.csv",
                                                                                                    "name": "Module_statistics_07-32-33.csv",
                                                                                                    "size": 93514
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize8_merge_ME_0.85_24326/kME_table_07-32-33.csv",
                                                                                                    "name": "kME_table_07-32-33.csv",
                                                                                                    "size": 1301452
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize8_merge_ME_0.85_24326/Module_statistics_07-32-33.pdf",
                                                                                                    "name": "Module_statistics_07-32-33.pdf",
                                                                                                    "size": 168868
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-32-33_TOPMODPOSBC/GSHyperG_BROAD_SETS_07-32-33_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_07-32-33_TOPMODPOSBC.csv",
                                                                                                                    "size": 50587764
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-32-33_TOPMODPOSBC/BroadGSHG_enrichment_by_set_07-32-33_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_07-32-33_TOPMODPOSBC.pdf",
                                                                                                                    "size": 17441485
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-32-33_TOPMODPOSFDR/GSHyperG_BROAD_SETS_07-32-33_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_07-32-33_TOPMODPOSFDR.csv",
                                                                                                                    "size": 53090065
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_07-32-33_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_07-32-33_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_07-32-33_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 18377914
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-32-33_TOPMODPOSBC/GSHyperG_MY_SETS_07-32-33_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_07-32-33_TOPMODPOSBC.csv",
                                                                                                                    "size": 6651604
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-32-33_TOPMODPOSBC/MyGSHG_enrichment_by_set_07-32-33_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_07-32-33_TOPMODPOSBC.pdf",
                                                                                                                    "size": 1214613
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-32-33_TOPMODPOSFDR/GSHyperG_MY_SETS_07-32-33_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_07-32-33_TOPMODPOSFDR.csv",
                                                                                                                    "size": 7094539
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.398_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_07-32-33_TOPMODPOSFDR/MyGSHG_enrichment_by_set_07-32-33_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_07-32-33_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 1284780
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.95,
                                                                                        "Signum": 0.3069247,
                                                                                        "MinSize": 10.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.95,
                                                                                                "Signum": 0.3069247,
                                                                                                "MinSize": 10.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 521.0,
                                                                                                "FinalModules": 323.0,
                                                                                                "Members": 7853.0,
                                                                                                "MeanSpecificity": 3.587003,
                                                                                                "MeanPC1VE": 0.4287952
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize10_merge_ME_0.85_24326/ME_network_10-47-55.pdf",
                                                                                                    "name": "ME_network_10-47-55.pdf",
                                                                                                    "size": 500455
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize10_merge_ME_0.85_24326/Module_statistics_10-47-55.csv",
                                                                                                    "name": "Module_statistics_10-47-55.csv",
                                                                                                    "size": 108415
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize10_merge_ME_0.85_24326/ME_correlations_10-47-55.pdf",
                                                                                                    "name": "ME_correlations_10-47-55.pdf",
                                                                                                    "size": 45209108
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize10_merge_ME_0.85_24326/Module_eigengenes_10-47-55.csv",
                                                                                                    "name": "Module_eigengenes_10-47-55.csv",
                                                                                                    "size": 1971607
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize10_merge_ME_0.85_24326/Modules_10-47-55.pdf",
                                                                                                    "name": "Modules_10-47-55.pdf",
                                                                                                    "size": 20957556
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize10_merge_ME_0.85_24326/kME_table_10-47-55.csv",
                                                                                                    "name": "kME_table_10-47-55.csv",
                                                                                                    "size": 1319432
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize10_merge_ME_0.85_24326/Module_statistics_10-47-55.pdf",
                                                                                                    "name": "Module_statistics_10-47-55.pdf",
                                                                                                    "size": 193337
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_10-47-55_TOPMODPOSBC/GSHyperG_BROAD_SETS_10-47-55_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_10-47-55_TOPMODPOSBC.csv",
                                                                                                                    "size": 54805306
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_10-47-55_TOPMODPOSBC/BroadGSHG_enrichment_by_set_10-47-55_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_10-47-55_TOPMODPOSBC.pdf",
                                                                                                                    "size": 17152782
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_10-47-55_TOPMODPOSFDR/GSHyperG_BROAD_SETS_10-47-55_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_10-47-55_TOPMODPOSFDR.csv",
                                                                                                                    "size": 57628276
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize10_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_10-47-55_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_10-47-55_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_10-47-55_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 17777321
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_10-47-55_TOPMODPOSBC/GSHyperG_MY_SETS_10-47-55_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_10-47-55_TOPMODPOSBC.csv",
                                                                                                                    "size": 7279986
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_10-47-55_TOPMODPOSBC/MyGSHG_enrichment_by_set_10-47-55_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_10-47-55_TOPMODPOSBC.pdf",
                                                                                                                    "size": 1138171
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_10-47-55_TOPMODPOSFDR/GSHyperG_MY_SETS_10-47-55_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_10-47-55_TOPMODPOSFDR.csv",
                                                                                                                    "size": 7779198
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.307_minSize10_merge_ME_0.85_24326/GSHyperG_MY_SETS_10-47-55_TOPMODPOSFDR/MyGSHG_enrichment_by_set_10-47-55_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_10-47-55_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 1181134
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                },
                                                                                {
                                                                                    "name": "FM",
                                                                                    "type": "transformation",
                                                                                    "attributes": {
                                                                                        "Analyst": [
                                                                                            "Rebecca Eliscu"
                                                                                        ],
                                                                                        "Similarity": null,
                                                                                        "RelSignum": 0.97,
                                                                                        "Signum": 0.3595226,
                                                                                        "MinSize": 8.0,
                                                                                        "MinMEcor": null
                                                                                    },
                                                                                    "files": [
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_project_attributes_11-29-09.csv",
                                                                                            "size": 735,
                                                                                            "name": "CGGA_RNAseq_325_project_attributes_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/makeFM.driver.R",
                                                                                            "size": 1206,
                                                                                            "name": "makeFM.driver.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv",
                                                                                            "size": 499,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_QA_log_11-29-09.csv"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt",
                                                                                            "size": 10199,
                                                                                            "name": "CGGA_RNAseq_325_makeFM_sessionInfo_11-29-09.txt"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/FM_driver_11-44-10.R",
                                                                                            "size": 2069,
                                                                                            "name": "FM_driver_11-44-10.R"
                                                                                        },
                                                                                        {
                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                            "size": 9268,
                                                                                            "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                        }
                                                                                    ],
                                                                                    "children": [
                                                                                        {
                                                                                            "name": "Covariation network",
                                                                                            "type": "",
                                                                                            "attributes": {
                                                                                                "Owner": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Analyst": [
                                                                                                    "Rebecca Eliscu"
                                                                                                ],
                                                                                                "Similarity": null,
                                                                                                "RelSignum": 0.97,
                                                                                                "Signum": 0.3595226,
                                                                                                "MinSize": 8.0,
                                                                                                "MinMEcor": null,
                                                                                                "InitialModules": 582.0,
                                                                                                "FinalModules": 371.0,
                                                                                                "Members": 7288.0,
                                                                                                "MeanSpecificity": null,
                                                                                                "MeanPC1VE": 0.4836557
                                                                                            },
                                                                                            "files": [
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv",
                                                                                                    "size": 9268,
                                                                                                    "name": "Bicor-None_p1_CGGA_RNAseq_325_24326_network_statistics_11-44-10.csv"
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize8_merge_ME_0.85_24326/Modules_08-29-17.pdf",
                                                                                                    "name": "Modules_08-29-17.pdf",
                                                                                                    "size": 21854361
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize8_merge_ME_0.85_24326/Module_statistics_08-29-17.csv",
                                                                                                    "name": "Module_statistics_08-29-17.csv",
                                                                                                    "size": 124382
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize8_merge_ME_0.85_24326/Module_eigengenes_08-29-17.csv",
                                                                                                    "name": "Module_eigengenes_08-29-17.csv",
                                                                                                    "size": 2266530
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize8_merge_ME_0.85_24326/kME_table_08-29-17.csv",
                                                                                                    "name": "kME_table_08-29-17.csv",
                                                                                                    "size": 1315242
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize8_merge_ME_0.85_24326/ME_network_08-29-17.pdf",
                                                                                                    "name": "ME_network_08-29-17.pdf",
                                                                                                    "size": 586287
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize8_merge_ME_0.85_24326/Module_statistics_08-29-17.pdf",
                                                                                                    "name": "Module_statistics_08-29-17.pdf",
                                                                                                    "size": 215857
                                                                                                },
                                                                                                {
                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize8_merge_ME_0.85_24326/ME_correlations_08-29-17.pdf",
                                                                                                    "name": "ME_correlations_08-29-17.pdf",
                                                                                                    "size": 58685855
                                                                                                }
                                                                                            ],
                                                                                            "children": [
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_08-29-17_TOPMODPOSBC/GSHyperG_BROAD_SETS_08-29-17_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_08-29-17_TOPMODPOSBC.csv",
                                                                                                                    "size": 59867237
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_08-29-17_TOPMODPOSBC/BroadGSHG_enrichment_by_set_08-29-17_TOPMODPOSBC.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_08-29-17_TOPMODPOSBC.pdf",
                                                                                                                    "size": 21534258
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_08-29-17_TOPMODPOSFDR/GSHyperG_BROAD_SETS_08-29-17_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_BROAD_SETS_08-29-17_TOPMODPOSFDR.csv",
                                                                                                                    "size": 62408525
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize8_merge_ME_0.85_24326/GSHyperG_BROAD_SETS_08-29-17_TOPMODPOSFDR/BroadGSHG_enrichment_by_set_08-29-17_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "BroadGSHG_enrichment_by_set_08-29-17_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 21814515
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_08-29-17_TOPMODPOSBC/GSHyperG_MY_SETS_08-29-17_TOPMODPOSBC.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_08-29-17_TOPMODPOSBC.csv",
                                                                                                                    "size": 7946495
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_08-29-17_TOPMODPOSBC/MyGSHG_enrichment_by_set_08-29-17_TOPMODPOSBC.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_08-29-17_TOPMODPOSBC.pdf",
                                                                                                                    "size": 1320957
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                },
                                                                                                {
                                                                                                    "name": "EA",
                                                                                                    "type": "transformation",
                                                                                                    "attributes": {
                                                                                                        "Analyst": [
                                                                                                            "Rebecca Eliscu"
                                                                                                        ],
                                                                                                        "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                    },
                                                                                                    "files": [
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/EA_driver_11-44-10.R",
                                                                                                            "size": 1448,
                                                                                                            "name": "EA_driver_11-44-10.R"
                                                                                                        },
                                                                                                        {
                                                                                                            "key": "CGGA_RNAseq_325_makeFM_11-29-09/GSEAfxsV3.1.R",
                                                                                                            "size": 43549,
                                                                                                            "name": "GSEAfxsV3.1.R"
                                                                                                        }
                                                                                                    ],
                                                                                                    "children": [
                                                                                                        {
                                                                                                            "name": "Enrichment results",
                                                                                                            "type": "",
                                                                                                            "attributes": {
                                                                                                                "Owner": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Analyst": [
                                                                                                                    "Rebecca Eliscu"
                                                                                                                ],
                                                                                                                "Statistical Test": "Fisher's Exact test (one-sided)"
                                                                                                            },
                                                                                                            "files": [
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_08-29-17_TOPMODPOSFDR/GSHyperG_MY_SETS_08-29-17_TOPMODPOSFDR.csv",
                                                                                                                    "name": "GSHyperG_MY_SETS_08-29-17_TOPMODPOSFDR.csv",
                                                                                                                    "size": 8400764
                                                                                                                },
                                                                                                                {
                                                                                                                    "key": "CGGA_RNAseq_325_makeFM_11-29-09/Bicor-None_signum0.36_minSize8_merge_ME_0.85_24326/GSHyperG_MY_SETS_08-29-17_TOPMODPOSFDR/MyGSHG_enrichment_by_set_08-29-17_TOPMODPOSFDR.pdf",
                                                                                                                    "name": "MyGSHG_enrichment_by_set_08-29-17_TOPMODPOSFDR.pdf",
                                                                                                                    "size": 1373850
                                                                                                                }
                                                                                                            ],
                                                                                                            "children": []
                                                                                                        }
                                                                                                    ]
                                                                                                }
                                                                                            ]
                                                                                        }
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        }
                                                                    ]
                                                                }
                                                            ]
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}